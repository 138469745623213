export function formatTinyPrice (price: number | string): string { 
    const threshold = 0.00001;

    const num = typeof price === 'string' ? parseFloat(price) : price;
    if (num >= threshold) {
        return num.toString();
    }

    const numStr = num.toFixed(12);
    // console.log(numStr);
    const match = numStr.match(/0\.(0+)/);

    if (match) {
        const zeroCount = match[1].length;
        const formattedNumber = numStr.replace(/0\.(0+)/, `0.0{${zeroCount}}`);
        return formattedNumber;
    }

    return numStr; // Return the original number if no match
}