import { ethers, Provider, TransactionResponse } from 'ethers';
import { Uniswapv3SwapRouter } from '../abi/Uniswapv3SwapRouter.abi';
import { connect } from './dapp';
import { Chain } from '../config/chains';
import { Permit2 } from '../abi/Permit2.abi';
import { UniversalRouter } from '../abi/IUniversalSwap.abi';
import { AllowanceTransfer, PermitDetails, PermitSingle } from '@uniswap/permit2-sdk';
import { QUOTE_ABI } from "../abi/IQuota.abi";

export function parseQuotaOptionsPath(path: string) {
    // 从options输入的path为：token0,500,token1,3000,token2类似格式
    // 之后把token地址的0x去掉，转成小写，然后转成uint8array，拼接到主串
    // 20字节token地址 + 3字节费率池子长度 + 20字节token地址 + 3字节费率池子长度 + ...
    // 最后转为hex输出
    const pathArr = path.split(',');
    // pathArr一定是奇数
    if (pathArr.length % 2 === 0) {
        throw new Error('pathArr length must be odd number');
    }
    const ADDR_SIZE = 20;
    const FEE_SIZE = 3;

    const addressCounts = (pathArr.length + 1) / 2;

    const uint8array = new Uint8Array(addressCounts * ADDR_SIZE + (addressCounts - 1) * FEE_SIZE);

    let offset = 0;
    for (let i = 0; i < pathArr.length; i++) {
        if (i % 2 === 0) {
            // const token = pathArr[i].toLowerCase().replace('0x', '');
            const tokenBytes = ethers.getBytes(pathArr[i])
            // 这里正好20字节
            // console.log(tokenBytes);
            // 放入uint8array
            uint8array.set(tokenBytes, offset);
            offset += ADDR_SIZE;
        } else {
            const fee = parseInt(pathArr[i]);
            // 3字节
            const _feeBytes = ethers.toBeHex(fee, FEE_SIZE);
            const feeBytes = ethers.getBytes(_feeBytes);
            // console.log(feeBytes);
            // 放入uint8array
            uint8array.set(feeBytes, offset);
            offset += FEE_SIZE;
        }
    }
    return ethers.hexlify(uint8array);
}

export interface ExactInputSingleParams {
    tokenIn: string;
    tokenOut: string;
    fee: number;
    recipient?: string;
    deadline: bigint;
    amountIn: bigint;
    amountOutMinimum: bigint;
    sqrtPriceLimitX96: bigint;

}

// interface PermitDetails {
//     token: string;
//     amount: bigint;
//     expiration: bigint;
//     nonce: bigint;
// }

// interface PermitSingle {
//     details: PermitDetails;
//     spender: string;
//     sigDeadline: bigint;
// }

export async function exactInputSingle(chain: Chain, params: ExactInputSingleParams, amountOut?: bigint): Promise<TransactionResponse> {
    const conn = await connect();

    if (!conn) {
        throw new Error('No provider');
    }

    const provider = conn.provider;
    const signer = await provider.getSigner();

    params.recipient = params.recipient || await signer.getAddress();
    const router = new ethers.Contract(chain.uniswapV3.swapRouter, Uniswapv3SwapRouter, signer);
    if (chain.uniswapV3.weth === params.tokenIn) {
        console.log('exactInputSingle', params, { value: params.amountIn })
        const tx = await router.exactInputSingle(params, { value: params.amountIn });
        return tx;
    } else {
        // const tx = await router.exactInputSingle(params);// 这个不会自动解包weth
        // 解决方案1：universal-router来执行。 https://github.com/Uniswap/universal-router/blob/main/README.md
        // command0: 0x0a - PERMIT2_PERMIT - 因为合约没实现permit，所以通过setapprove授权给permit2合约
        // command1: 0x00 - V3_SWAP_EXACT_IN
        // command2: 0x0c - UNWRAP_WETH
        // e.g:  command : 0x0a000c means permit2, V3_SWAP_EXACT_IN, UNWRAP_WETH

        // 是否approve给permit2合约了？ 如果没有，先approve给permit2合约
        const isApproved = await isApprovedEnough(params.tokenIn, chain.uniswapV3.permit2, params.amountIn);
        if (!isApproved) {
            const tx = await approveMax(params.tokenIn, chain.uniswapV3.permit2);
            await tx.wait();
        }

        // get nonce from Permit2
        const permit2 = new ethers.Contract(chain.uniswapV3.permit2, Permit2, signer);
        const allowanceRes = await permit2.allowance(await signer.getAddress(), params.tokenIn, chain.uniswapV3.universalRouter);
        const _amount = allowanceRes[0];
        const _expiration = allowanceRes[1];
        const _nonce = allowanceRes[2];

        const universalRouter = new ethers.Contract(chain.uniswapV3.universalRouter, UniversalRouter, signer);
        console.log(`_amount: ${_amount}, _expiration: ${_expiration}, _nonce: ${_nonce}`)
        if (_amount < params.amountIn || _expiration < new Date().getTime() / 1000 + 15 * 60) {

            const command = '0x0a000c'
            const sigDeadline = BigInt(parseInt(new Date().getTime() / 1000 + 1200 + ''))
            const expiration = BigInt(parseInt(new Date().getTime() / 1000 + 30 * 86400 + ''))

            const permitDetails: PermitDetails = {
                token: params.tokenIn,
                amount: BigInt('0xffffffffffffffffffffffffffffffffffffffff'),
                expiration: expiration,
                nonce: _nonce
            }
            
            const permitSingle: PermitSingle = {
                details: permitDetails,
                spender: chain.uniswapV3.universalRouter,
                sigDeadline: sigDeadline
            }

            const abiCoder = new ethers.AbiCoder()

            const permitSingleData = AllowanceTransfer.getPermitData(permitSingle, chain.uniswapV3.permit2, Number(chain.chainId))
            const signature = await signer.signTypedData(
                permitSingleData.domain,
                permitSingleData.types,
                permitSingleData.values
            )

            console.log("permitSingleData", permitSingleData)
            console.log("signature", signature)
            // InvalidSigner

            const PERMIT_STRUCT =
                '((address token,uint160 amount,uint48 expiration,uint48 nonce) details,address spender,uint256 sigDeadline)'

            const commandDefination = [PERMIT_STRUCT, 'bytes']
            const input0 = abiCoder.encode(commandDefination, [permitSingle, signature])

            const path = `${params.tokenIn},${params.fee},${params.tokenOut}`
            const input1 = abiCoder.encode(['address', 'uint256', 'uint256', 'bytes', 'bool'], [
                // chain.uniswapV3.universalRouter,
                "0x0000000000000000000000000000000000000002",
                params.amountIn,
                params.amountOutMinimum,
                parseQuotaOptionsPath(path),
                true
            ])
            const input2 = abiCoder.encode(['address', 'uint256'], [
                params.recipient,
                params.amountOutMinimum
            ])
            const tx = await universalRouter['execute(bytes calldata commands, bytes[] calldata inputs, uint256 deadline)'](command, [input0, input1, input2], sigDeadline);
            // const tx = await universalRouter.execute(command, [input0, input1, input2]);
            return tx;
        } else {
            const command = '0x000c'

            const abiCoder = new ethers.AbiCoder()
            const path = `${params.tokenIn},${params.fee},${params.tokenOut}`
            const input1 = abiCoder.encode(['address', 'uint256', 'uint256', 'bytes', 'bool'], [
                // chain.uniswapV3.universalRouter,
                "0x0000000000000000000000000000000000000002",
                params.amountIn,
                params.amountOutMinimum,
                parseQuotaOptionsPath(path),
                true
            ])
            const input2 = abiCoder.encode(['address', 'uint256'], [
                params.recipient,
                params.amountOutMinimum
            ])
            const deadline = BigInt(parseInt(new Date().getTime() / 1000 + 1200 + ''))
            // const tx = await universalRouter.execute(command, [input1, input2]);
            const tx = await universalRouter['execute(bytes calldata commands, bytes[] calldata inputs, uint256 deadline)'](command, [input1, input2], deadline);
            return tx;
        }

    }
}

export interface ExactOutputSingleParams {
    tokenIn: string;
    tokenOut: string;
    fee: number;
    recipient?: string;
    deadline: bigint;
    amountOut: bigint;
    amountInMaximum: bigint;
    sqrtPriceLimitX96: bigint;
}

export async function exactOutputSingle(chain: Chain, params: ExactOutputSingleParams): Promise<TransactionResponse> {

    const conn = await connect();
    if (!conn) {
        throw new Error('No provider');
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();

    params.recipient = params.recipient || await signer.getAddress();

    const router = new ethers.Contract(chain.uniswapV3.swapRouter, Uniswapv3SwapRouter, signer);
    if (chain.uniswapV3.weth === params.tokenIn) {
        const tx = await router.exactOutputSingle(params, {
            value: params.amountInMaximum
        });
        return tx;
    } else {
        const tx = await router.exactOutputSingle(params);
        return tx;
    }
}

export async function isApprovedEnough(token: string, spender: string, amount: bigint): Promise<boolean> {
    const conn = await connect();
    if (!conn) {
        throw new Error('No provider');
    }

    const provider = conn.provider;
    const signer = await provider.getSigner();

    const tokenContract = new ethers.Contract(token, ['function allowance(address owner, address spender) view returns (uint256)'], signer);
    const allowance = await tokenContract.allowance(await signer.getAddress(), spender);
    console.log('allowance', allowance, amount);
    console.log('allowance >= amount', allowance >= amount)
    return allowance >= amount;
}

// approve max
export async function approveMax(token: string, spender: string): Promise<TransactionResponse> {
    const conn = await connect();
    if (!conn) {
        throw new Error('No provider');
    }
    const provider = conn.provider;
    
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(token, ['function approve(address spender, uint256 amount) returns (bool)'], signer);

    const tx = await tokenContract.approve(spender, ethers.MaxUint256);
    return tx;
}


export async function exactInput(path: string, amountIn: bigint): Promise<void> {
    throw new Error('Not implemented');
}

export async function exactOutput(path: string, amountOut: bigint): Promise<void> {
    throw new Error('Not implemented');
}


/**
 * calculate one token's market cap in usdt
 * @param chain 
 * @param address 
 * @param provider 
 * @returns 
 */
export async function marketCap(chain: Chain, address: string, provider: Provider, totalSupplly?: number | bigint): Promise<bigint> {

    // const path = `${chain.uniswapV3.usdt},${chain.uniswapV3.usdtFeePool},${chain.uniswapV3.weth},${chain.feePool},${address}`;

    // const encodedPath = parseQuotaOptionsPath(path);
    // const quoteContract = new ethers.Contract(chain.uniswapV3.quoteContract, QUOTE_ABI, provider);
    // const amountOut = ethers.parseEther("1")

    // const quote = await quoteContract.quoteExactOutput.staticCall(
    //     encodedPath,
    //     amountOut
    // );
    // console.log('quote', quote);
    // const amountIn = quote[0];
    // return totalSupplly ? amountIn * BigInt(totalSupplly) : amountIn;

    const path = `${address},${chain.feePool},${chain.uniswapV3.weth},${chain.uniswapV3.usdtFeePool},${chain.uniswapV3.usdt}`
    const encodedPath = parseQuotaOptionsPath(path);
    const quoteContract = new ethers.Contract(chain.uniswapV3.quoteContract, QUOTE_ABI, provider);
    const amountIn = ethers.parseEther("1")
    const quote = await quoteContract.quoteExactInput.staticCall(
        encodedPath,
        amountIn
    );
    const amountOut = quote[0];
    return totalSupplly ? amountOut * BigInt(totalSupplly) / BigInt(1e18) : amountOut;
}