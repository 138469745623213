import React from "react";
import Header from "../components/header/Header";
import { CHAINS, CurrentChain } from "../config/chains";
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, switchNetwork, toEIP55, tryConnectSilent, watchTxRecp } from "../utils/dapp";
import { Contract, ethers, Provider, TransactionReceipt, TransactionResponse } from "ethers";
import { Project, ProjectMeta, ProjectMetaItem } from "../components/project/ProjectCard";
import { IFairLaunchTokenABI } from "../abi/IFairLaunchToken.abi";
import { API, QR } from "../config/api";
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

import "./DetailPage2.css";
import { doClaimETH, doFund, doMint, doRefund, doStart } from "../utils/fairlaunch";
import TradeWidget from "../components/trader/TradeWidget";
import Footer from "../components/footer/Footer";

import CopyIcon from '../assets/icon/copy.svg';
import QrCodeIcon from '../assets/icon/qr.svg';
import InformationIcon from '../assets/icon/information.png';
import ErrorIcon from '../assets/icon/error.png';
import SuccessIcon from '../assets/icon/success.png';
import WaitingIcon from '../assets/icon/waiting.png';
import copy from "copy-to-clipboard";
import { marketCap } from "../utils/swap";
import BadgeTimer from "../components/timer/BadgeTimer";

import CyberScopeImage from '../assets/image/cyberscope.png';
import { hashAvatar } from "../utils/avatar";
import ShareImage from "../assets/image/share.png";

import UniswapLogo from '../assets/logo/uniswap.svg';
import TrendIcon from '../assets/icon/trend.png';
import { formatTinyPrice } from "../utils/format";

import EmptyImage from '../assets/image/empty.png';


interface DetailPage2Props {
    projectAddress: string;
}

interface DetailPage2State {
    chain?: any;
    address?: string;
    blocknumber?: number;
    provider?: Provider;
    project?: Project;
    meta?: ProjectMeta;

    untilBlockNumber?: number;
    fundBalanceOf: bigint;
    mightGet: bigint;
    getExtraETH?: bigint;
    started?: boolean;
    canStart?: boolean;
    minted?: boolean;
    claimed?: boolean;

    totalEthers: bigint;
    amountInput: string;

    showAmountInput: boolean;
    showAlert: boolean;
    showConfirm: boolean;
    onConfirm?: Function;
    confirmMessage: string;
    txHash: string | null;
    timer: any;
    alertMessage: string;
    showTxResult: boolean;

    fundEvents: Array<FundEvent>;

    txReceipt?: TransactionReceipt | null;
    projectOwner: string;

    bannerProject?: Project;
    bannerFundEvents: Array<FundEvent>;
    bannerFundEvent?: FundEvent;
    animation: boolean;

    currentIndex: number;

    showQR: boolean;

    price: bigint;

    showShare: boolean;
    // [propName: string]: any;
}

interface FundEvent {
    hash: string;
    from: string;
    ethAmount: string;
    blockNumber: number;
    timestamp: number;
    project: Project;
}

class DetailPage2 extends React.Component<DetailPage2Props, DetailPage2State> {

    constructor(props: DetailPage2Props) {
        super(props);
        this.state = {
            chain: undefined,
            address: undefined,
            blocknumber: 0,
            provider: undefined,
            project: undefined,
            meta: undefined,

            untilBlockNumber: undefined,
            fundBalanceOf: BigInt(0),
            mightGet: BigInt(0),
            claimed: undefined,
            getExtraETH: undefined,
            started: false,
            canStart: false,
            minted: false,
            totalEthers: BigInt(0),

            showAmountInput: false,
            showAlert: false,
            showConfirm: false,
            onConfirm: undefined,
            confirmMessage: '',
            txHash: null,
            timer: null,

            amountInput: '0.05',
            alertMessage: '',

            showTxResult: false,
            txReceipt: undefined,
            projectOwner: '',

            fundEvents: [],
            bannerProject: undefined,
            bannerFundEvents: [],
            bannerFundEvent: undefined,

            animation: true,
            currentIndex: 0,

            showQR: false,

            price: BigInt(0),

            showShare: false,

        };
    }

    componentDidMount() {
        this.initSilent();
        this.loadProject();
        this.loadFundEvents();
        this.loadDeployedProjectFromREST();
        this.loadFundEventsFromREST();

        setInterval(() => {
            this.setState({ animation: !this.state.animation });
            if (this.state.bannerFundEvents.length > 0) {
                const nextIndex = this.state.currentIndex + 1 >= this.state.bannerFundEvents.length ? 0 : this.state.currentIndex + 1;
                this.setState({
                    bannerFundEvent: this.state.bannerFundEvents[nextIndex],
                    currentIndex: nextIndex,
                });
            }
        }, 1000);
    }

    loadProject = async () => {
        // fetch 
        const url = `${API}/project/detail/${this.props.projectAddress}`;
        const res = await fetch(url);
        const data = await res.json();
        if (data.code === 1) {
            this.setState({
                project: data.data,
                meta: this.parseMeta(data.data.meta)
            });
        }
    }

    loadFundEvents = async () => {
        const url = `${API}/event/fundEvent/project/${this.props.projectAddress}`;
        const res = await fetch(url);
        const data = await res.json();
        if (data.code === 1) {
            this.setState({
                fundEvents: data.data.content ? data.data.content : data.data ? data.data : []
            });
        }
    }

    loadDeployedProjectFromREST = async () => {
        const url = `${API}/project/list?status=0&size=1`;
        const response = await fetch(url);
        const result = await response.json();

        if (result.code === 1) {
            const data = result.data.content;
            if (data.length > 0) {
                const project = data[0];
                this.setState({ bannerProject: project });
            }
        }
    }

    loadFundEventsFromREST = async () => {
        const url = `${API}/event/fundEvent?size=10`;
        const response = await fetch(url);
        const result = await response.json();
        if (result.code === 1) {
            const data = result.data.content;
            if (data.length > 0) {
                this.setState({ bannerFundEvents: data, bannerFundEvent: data.length > 0 ? data[0] : undefined });
            }
        }
    }

    init = async () => {
        // try connect with popup
        if (!isInWeb3Browser()) {
            console.error('not in web3 browser');
            this.alert('Please use a web3 browser');
            return;
        }

        const res = await connect();
        if (res) {
            const chain = CHAINS[Number(res.chainId)];
            if (!chain || chain.chainId !== CurrentChain.chainId) {
                console.error('chain not supported', res.chainId);
                // this.alert('Chain not supported');
                switchNetwork(CurrentChain, () => {
                    this.init()
                });
                return;
            }

            this.setState({
                provider: res.provider,
                blocknumber: res.blocknumber,
                chain: chain,
                address: res.address,
            });
            this.watchChange();
            if (res.address) {
                this.looadAddressStatus(res.address, res.provider);
            }
        }
    }

    initSilent = async () => {
        this.loadProjectBaseData();
        if (!isInWeb3Browser()) {
            console.error('not in web3 browser');
            return;
        }
        // try silent connect first
        try {
            const res = await tryConnectSilent();
            if (res) {

                const chain = CHAINS[Number(res.chainId)];
                if (!chain) {
                    console.error('chain not supported', res.chainId);
                    return;
                }

                this.setState({
                    provider: res.provider,
                    blocknumber: res.blocknumber,
                    chain: chain
                });

                if (res.address) {
                    this.setState({
                        address: res.address,
                    });
                }

                this.watchChange();
                this.initTimer(chain, res.provider);
                this.loadProjectStatus(res.provider);
                this.loadProjectLaunchParams(res.provider);

                if (res.address) {
                    this.looadAddressStatus(res.address, res.provider);
                }
            } else {
                this.loadProjectBaseData();
            }

        } catch (e) {
            // this.loadProjectBaseData();
            console.error(e);
        }
    };

    loadProjectLaunchParams = async (provider: Provider) => {
        // untilBlockNumber
        if (this.state.chain && this.state.chain.chainId === CurrentChain.chainId) {
            const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
            const untilBlockNumber = await contract.untilBlockNumber();
            this.setState({
                untilBlockNumber: untilBlockNumber
            });
        }

    }

    loadProjectBaseData = async () => {
        // fetch from rest if not in web3 browser
        const response = await fetch(`${API}/project/rpc?address=${this.props.projectAddress}`);
        const data = await response.json();
        if (data.code === 1) {
            // const info = JSON.parse(data.data);
            const info = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            this.setState({
                totalEthers: BigInt(info.totalEthers),
                untilBlockNumber: info.untilBlockNumber,
                started: info.started,
                canStart: info.canStart,
                blocknumber: info.blocknumber,
            });
        }

        // const response2 = await fetch(`${API}/currentBlock`);
        // const data2 = await response2.json();
        // if (data2.code === 1) {
        //     this.setState({
        //         blocknumber: data2.data
        //     });
        // }
    }

    loadProjectStatus = async (provider: Provider) => {

        if (!this.state.chain || this.state.chain.chainId !== CurrentChain.chainId) {
            return;
        }

        const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
        // started
        const started = await contract.started();

        // canStart
        const canStart = await contract.canStart();

        // totalEthers
        const totalEthers = await contract.totalEthers();

        // projectOwner
        const projectOwner = await contract.projectOwner();

        this.setState({
            started: started,
            canStart: canStart,
            totalEthers: totalEthers,
            projectOwner: projectOwner
        });

        if (started) {
            if (this.state.chain !== undefined) {
                try {
                    const theMarketCap = await marketCap(this.state.chain, this.props.projectAddress, provider);
                    this.setState({
                        price: theMarketCap
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    looadAddressStatus = async (address: string, provider: Provider) => {
        try {
            if (!address || '' === address) {
                return;
            }
            const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
            const getExtraETH = await contract.getExtraETH(address);
            const claimed = await contract.claimed(address);
            const mightGet = await contract.mightGet(address);
            const fundBalanceOf = await contract.fundBalanceOf(address);
            // minted
            const minted = await contract.minted(address);
            this.setState({
                getExtraETH: getExtraETH,
                claimed: claimed,
                mightGet: mightGet,
                fundBalanceOf: fundBalanceOf,
                minted: minted,
            });
        } catch (e) {

            console.error(e)
        }
    }

    initTimer = (chain: any, provider: Provider): void => {
        const that = this

        provider.on('block', async (blockNumber: number) => {
            that.setState({
                blocknumber: blockNumber
            });
            that.loadProject()
            that.loadFundEvents();
            if (this.state.chain && this.state.chain.chainId === CurrentChain.chainId) {
                that.loadProjectStatus(provider);
                this.looadAddressStatus(that.state.address ?? "", provider);
            }
        })

    }

    parseMeta(meta: string): ProjectMeta | undefined {
        let obj: Array<ProjectMetaItem> = [];
        try {
            // data:application/json;base64,
            const _meta = meta.split(',')[1];
            obj = JSON.parse(
                atob(_meta)
            );
            let result: ProjectMeta = {};
            obj.forEach((element: ProjectMetaItem) => {
                result[element.trait_type] = element.value;
            });
            return result;
        } catch (error) {
            console.error(error);
        }
        return undefined;
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.initSilent();
        })
    }

    alert = (message: string) => {
        this.setState({
            showAlert: true,
            alertMessage: message
        });
    }

    formatDate(timestamp: number): string {
        // hh:mm:ss
        const date = new Date(timestamp);
        // to HH:mm:ss
        return date.toTimeString().substring(0, 8);
    }


    doFund = async () => {
        const tx = await doFund(this.props.projectAddress, ethers.parseEther(this.state.amountInput))

        this.setState({
            showAmountInput: false,
            showTxResult: true,
            txHash: tx?.hash ?? null
        });
        const that = this
        if (tx && tx.hash && this.state.provider) {
            watchTxRecp(this.state.timer, tx.hash, this.state.provider, (recp: any) => {
                that.setState({
                    timer: null,
                    txReceipt: recp,
                });
            })
        }
    }

    doRefund = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to refund?',
            onConfirm: async () => {
                const tx = await doRefund(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    doLaunch = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to launch?',
            onConfirm: async () => {
                try {
                    const tx = await doStart(that.props.projectAddress);
                    that.setState({
                        showConfirm: false,
                        onConfirm: undefined,
                        txHash: tx?.hash || null,
                        showTxResult: true
                        ,
                    });

                    if (tx && tx.hash && that.state.provider) {
                        watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                            that.setState({
                                timer: null,
                                txReceipt: recp
                            });
                        })
                    }
                } catch (e: any) {
                    console.error(JSON.stringify(e));
                    that.alert(e.message);
                }
            }
        });
    }

    doClaim = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to claim?',
            onConfirm: async () => {
                const tx = await doMint(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    doClaimETH = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to claim extra eth?',
            onConfirm: async () => {
                const tx = await doClaimETH(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    render() {
        const { project, meta, totalEthers, started, price, canStart } = this.state;
        return (
            <div style={{
                overflowX: 'hidden'
            }}>
                <Header showMessage={false} chain={this.state.chain} onConnectClick={() => {
                    this.init();
                }} address={this.state.address ?? ""} />
                <div className="detail2Body">
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <Row>
                                <Col sm={12} md={6} lg={6}>{this.state.bannerFundEvent ? <div className={"headerPad1 " + (this.state.animation ? 'headerAnimation' : '')}>
                                    {/* aaa fund 0.001 eth for bbb */}
                                    {/* 滚动显示fund events */}
                                    <div className="scroll-text z4">
                                        <img src={this.parseMeta(this.state.bannerFundEvent?.project?.meta ?? "")?.image} alt='icon' style={{
                                            width: "1.2rem",
                                            height: "1.2rem",
                                            marginRight: "0.5rem",
                                            borderRadius: "50%",
                                        }}>
                                        </img><span>{
                                            this.state.bannerFundEvent?.from.substring(0, 6) + '...' + this.state.bannerFundEvent?.from.substring(this.state.bannerFundEvent?.from.length - 4)
                                        } pay {
                                                ethers.formatEther(this.state.bannerFundEvent?.ethAmount + '')
                                            } ETH for <a style={{ color: "#000" }} href={`/dt?address=${this.state.bannerFundEvent.project?.address}`}>${this.state.bannerFundEvent.project?.symbol}</a>
                                            &nbsp;at {this.formatDate(this.state.bannerFundEvent?.timestamp ?? 0)}
                                        </span>
                                    </div>
                                </div> : null}
                                </Col>

                                <Col sm={12} md={6} lg={6}>
                                    <div className="only-mobile" style={{ height: '1rem' }}></div>
                                    {this.state.bannerProject ? <div className="headerPad2 z4">
                                        {/* xxxxx created xxxx at xx:xx:xx */}
                                        <div>
                                            <img src={hashAvatar(this.state.bannerProject.project?.creator ?? "0x00000000000000000000000000000000")} alt="" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.2rem',
                                                marginLeft: '0.5rem'
                                            }} />
                                            {this.state.bannerProject.creator.substring(0, 6) + '...' + this.state.bannerProject.creator.substring(this.state.bannerProject.creator.length - 4)} created&nbsp;
                                            <img src={this.parseMeta(this.state.bannerProject.meta)?.image} alt='icon' style={{
                                                width: "1.2rem",
                                                height: "1.2rem",
                                                marginRight: "0.5rem",
                                                borderRadius: "50%",
                                            }} />
                                            <a style={{ color: "#000" }} href={`/detail?address=${this.state.bannerProject.address}`}>{this.state.bannerProject.symbol}</a>
                                            &nbsp;at {this.formatDate(this.state.bannerProject.createdAt)}
                                        </div>
                                    </div> : null}
                                </Col>
                            </Row>
                            {/* end of banners */}
                            <div>
                                <div className="detail2Project">
                                    <div className="detail2ProjecImage">
                                        <img src={meta?.image} alt="icon" />

                                        <div>
                                            <span role="img" aria-label="fire">🔥</span> {project?.rate}
                                        </div>
                                    </div>
                                    <div className="detail2ProjectInfo z4">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.3rem',
                                        }}>
                                            <div>Created by </div>
                                            <img src={hashAvatar(this.state.project?.creator ?? "0x00000000000000000000000000000000")} alt="" style={{
                                                width: '0.8rem',
                                                height: '0.8rem',
                                                marginRight: '0.2rem',
                                                marginLeft: '0.5rem'
                                            }} />
                                            <div>
                                                {project?.creator.substring(0, 6) + '...' + project?.creator.substring(project?.creator.length - 4)}
                                            </div>

                                        </div>
                                        <div>Funds raised: <span style={{
                                            color: '#ffc107'
                                        }}>{
                                                ethers.formatEther(totalEthers ?? "0")
                                            } ETH  ({
                                                project ? (totalEthers * BigInt(100) / BigInt(project.softCap)).toString() : ''
                                            }%)</span>
                                        </div>
                                        <div>Participants: <span style={{ color: '#ffc107' }}>{project?.funders}</span></div>
                                        <div style={{
                                            display: 'none'
                                        }}>
                                            {started ? <div>Price: <span style={{ color: '#ffc107' }}>$
                                                {/* {this.toKMBT(price ?? BigInt(0), 12)} */}
                                                {price + ''} / {ethers.formatEther(price ?? '0')}
                                            </span></div> : null}
                                            {started ? <div>Market cap:  <span style={{ color: '#ffc107' }}>${
                                                this.toKMBT(price * BigInt(project?.totalSupply ?? 0) / BigInt(1E18))
                                            }</span>
                                            </div> : null}
                                        </div>
                                        <div className="z3">
                                            <b>{project?.name} ({project?.symbol})</b>: {decodeURIComponent(meta?.description ?? '')}
                                        </div>
                                        <div className="detail2ProjectSocial z4">
                                            {meta?.website ? <div onClick={() => {
                                                window.open(meta?.website, '_blank');
                                            }}
                                            >[website]</div> : null}
                                            {meta?.x ? <div onClick={() => {
                                                window.open(meta?.x, '_blank');
                                            }}>[twitter]</div> : null}
                                            {/* telegram */}
                                            {meta?.telegram ? <div onClick={() => {
                                                window.open(meta?.telegram, '_blank');
                                            }}>[telegram]</div> : null}
                                            {/* discord */}
                                            {meta?.discord ? <div onClick={() => {
                                                window.open(meta?.discord, '_blank');
                                            }}>[discord]</div> : null}
                                            {/* github */}
                                            {meta?.github ? <div onClick={() => {
                                                window.open(meta?.github, '_blank');
                                            }}>[github]</div> : null}
                                            {/* gitbook */}
                                            {meta?.gitbook ? <div onClick={() => {
                                                window.open(meta?.gitbook, '_blank');
                                            }}>[gitbook]</div> : null}
                                            {/* youtube */}
                                            {meta?.youtube ? <div onClick={() => {
                                                window.open(meta?.youtube, '_blank');
                                            }}>[youtube]</div> : null}

                                            <div style={{
                                                alignContent: 'center',
                                                display: 'flex',
                                                gap: '0.2rem',
                                                alignItems: 'center',
                                            }} onClick={() => {
                                                this.setState({
                                                    showShare: true
                                                });
                                            }}> [
                                                <img src={ShareImage} alt="share" style={{
                                                    width: '0.75rem',
                                                    height: '0.75rem',
                                                }} />
                                                Share]</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end of information */}
                            {started ? <div className="detail2marketInfo">
                                <div>
                                    <div>
                                        <img src={UniswapLogo} alt="uniswap" />
                                        <div>Listed on uniswap</div>
                                    </div>
                                    <div>Price:  <span style={{ color: '#ffc107' }}>${formatTinyPrice(ethers.formatEther(price ?? BigInt(0)))}</span> </div>
                                    <div>macket Cap:  <span style={{ color: '#ffc107' }}>${this.toKMBT(price * BigInt(project?.totalSupply ?? 0) / BigInt(1E18))}</span> </div>
                                </div>
                                <div>
                                    <img src={TrendIcon} alt="trade" className="pointer" onClick={() => {
                                        // document.location.href = `https://dexscreener.com/base/${this.props.projectAddress}`;
                                        // if is mobile system
                                        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
                                            document.location.href = `https://dexscreener.com/base/${this.props.projectAddress}`;
                                        } else {
                                            window.open(`https://dexscreener.com/base/${this.props.projectAddress}`, '_blank');
                                        }
                                    }} />
                                </div>
                            </div> : null}
                            <div className="detail2hightlight z4">
                                Deployed on Rocket Protocol.
                                <img src={CyberScopeImage} alt="cyberscope" style={{
                                    width: '1rem',
                                    height: '1rem',
                                    marginLeft: '0.5rem',
                                    marginRight: '0.5rem',
                                }} />
                                <a href="https://rocketmeme.gitbook.io/en/audit" target="_blank" rel="noreferrer">Audited by Cyberscope. </a>
                            </div>
                            {/* end of hightlight */}
                            <div>
                                <div className="detail2info1">
                                    <div className="z2">FairMint</div>
                                    <div>
                                        <BadgeTimer bg={this.state.started ? 'secondary' : 'info'}
                                            seconds={
                                                Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? (Number(this.state.untilBlockNumber) - Number(this.state.blocknumber)) * CurrentChain.blockSeconds : 0
                                            } blocks={
                                                Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) : 0
                                            } canStart={
                                                this.state.canStart ? true : false
                                            } started={
                                                this.state.started ? true : false}
                                        ></BadgeTimer>
                                    </div>
                                    <div className="z4 pointer" onClick={() => {
                                        document.location.href = "https://rocketmeme.gitbook.io/en/fairmint"
                                    }} >[How to participate?]</div>
                                </div>

                                <div>
                                    <div className="detail2info1 z3" style={{ marginTop: '1rem' }}>Send ETH to {project?.symbol}'s CA to participate in FairMint</div>
                                    <div className="detail2hightlight" style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            fontSize: '1rem',
                                            color: '#ffda6a',
                                        }}>
                                            <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',

                                            }} />
                                            <div style={{
                                                fontSize: '0.875rem',
                                                color: "#f59a23"
                                            }}>{project?.symbol}'s CA: {project?.address} </div>
                                        </div>
                                        <div>
                                            {/* qr  */}
                                            <img src={QrCodeIcon} alt="qr" style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                                cursor: 'pointer',
                                                marginRight: '0.8rem',
                                            }} onClick={() => {
                                                this.setState({
                                                    showQR: true
                                                });
                                            }} />
                                            {/* copy  */}
                                            <img src={CopyIcon} alt="copy" style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                                cursor: 'pointer',
                                            }} onClick={() => {
                                                copy(project?.address ?? "");
                                                this.alert('Copied to clipboard');
                                            }} />
                                        </div>
                                    </div>

                                </div>
                                
                                {/* infomation */}
                                <div className="detail2info2">
                                    <div className="detail2info2item">
                                        <div>Chain:</div>
                                        <div>
                                            {/* <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',
                                            }} /> */}
                                            {CurrentChain.name}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Total supply:</div>
                                        <div>
                                            {/* to 000,000,000,000 format */}
                                            {Number(ethers.formatEther(BigInt(project?.totalSupply ?? 0))).toLocaleString() + ' ' + project?.symbol}
                                            (50% FairMint, 50% added to LP)
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>LP Maximun Limit:</div>
                                        <div>
                                            {/* softCap / totalSupply / 2 */}
                                            {Number(ethers.formatEther(BigInt(project?.totalSupply ?? 0) / BigInt(2))).toLocaleString() + ' ' + project?.symbol} / {Number(ethers.formatEther(BigInt(project?.softCap ?? 0))) + ' ETH'}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Fairmint ending block:</div>
                                        <div>
                                            {'#' + this.state.untilBlockNumber}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Creator:</div>
                                        <div>
                                            {project?.creator.substring(0, 10) + '...' + project?.creator.substring(project?.creator.length - 8)}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Creation time:</div>
                                        <div>
                                            {new Date(project?.createdAt).toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={4} md={4} style={{
                            paddingLeft: '1.6rem',
                            paddingRight: '1.6rem',
                        }}>
                            <div className="only-mobile" style={{ height: '1rem' }}></div>
                            {this.state.started ? <Card style={{ backgroundColor: 'rgba(46,48,58,1)', marginBottom: '1rem' }}><Card.Body><TradeWidget
                                projectAddress={this.props.projectAddress}
                                symbol={project?.symbol}
                                logo={meta?.image}
                                chainId={this.state.chain?.chainId}
                                provider={this.state.provider}
                                address={this.state.address}
                                blockNumber={this.state.blocknumber}
                                onSwapFail={(error: Error) => {
                                    console.error(error)
                                    this.alert(error.message);
                                }}
                                onSwapSuccess={async (tx: TransactionResponse) => {
                                    const that = this
                                    if (tx && tx.hash && that.state.provider) {
                                        watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                                            that.setState({
                                                timer: null,
                                                txReceipt: recp
                                            });
                                        })
                                    }
                                    this.setState({
                                        txHash: tx.hash,
                                        showTxResult: true
                                    });
                                }}
                                onConnect={
                                    async () => {
                                        this.init();
                                    }
                                }
                            ></TradeWidget></Card.Body></Card> : null}

                            <Card style={{ backgroundColor: 'rgba(46,48,58,1)', border: 'none' }}>
                                <Card.Body>
                                    {!started && !canStart ? <Card.Text className="z3">You can participate in FairMint by sending ETH  to {project?.symbol}'s CA via your wallet, or by clicking the button below. </Card.Text> : null}
                                    {canStart && !started ? <Card.Text className="z3">FairMint has ended.</Card.Text> : null}
                                    <Card.Text className="z3">You minted: <span style={{
                                        color: '#ffc107'
                                    }}> {
                                            this.state.address ? ethers.formatEther(this.state.fundBalanceOf + '') : '--'
                                        } ETH</span> </Card.Text>

                                    {(canStart || started) && !this.state.minted ? <Card.Text className="z3">Estimated gain: <span style={{
                                        color: '#ffc107'
                                    }}> {
                                            this.state.mightGet > 0 ? '' + (Number(Number(ethers.formatEther(this.state.mightGet + '')).toFixed(4))) + ' ' + project?.symbol : '--'
                                        }</span></Card.Text> : null}

                                    {started ? <Card.Text className="z3">Extra ETH:
                                        <span style={{
                                            color: '#ffc107'
                                        }}>{this.state.getExtraETH && this.state.getExtraETH > 0 ? '' + (Number(Number(ethers.formatEther(this.state.getExtraETH + '')).toFixed(4))) + ' ETH' : '--'}</span>
                                    </Card.Text> : null}

                                    <div className="d-grid gap-2 btnMargin">
                                        {this.state.started && !this.state.minted ? <Button variant="outline-warning"
                                            style={{
                                                color: '#fff',
                                                border: '1px solid #F59A23',
                                            }}
                                            onClick={() => {
                                                this.doClaim()
                                            }}
                                        >
                                            Claim coins
                                        </Button> : null}
                                        {this.state.started && this.state.getExtraETH && this.state.getExtraETH > 0 && !this.state.claimed ? <Button variant="outline-warning" style={{
                                            color: '#fff',
                                            border: '1px solid #F59A23',
                                        }} onClick={() => {
                                            this.doClaimETH()
                                        }} >
                                            Claim extra ETH
                                        </Button> : null}
                                        {!this.state.started && !this.state.canStart ? <Button variant="warning"
                                            onClick={() => {
                                                this.setState({
                                                    showAmountInput: true
                                                });
                                            }}
                                        >
                                            Mint
                                        </Button> : null}
                                        {!this.state.started ? <Button variant="outline-success" style={{
                                            color: '#fff',
                                            border: '1px solid #97C21C',
                                        }} onClick={() => {
                                            this.doRefund()
                                        }}>
                                            Refund
                                        </Button> : null}
                                        {!this.state.started && this.state.canStart ? <Button variant="warning" onClick={() => {
                                            this.doLaunch()
                                        }} >
                                            Launch
                                        </Button> : null}
                                        {!this.state.address ? <Button variant="info" onClick={() => {
                                            this.init();
                                        }}>
                                            Connect
                                        </Button> : null}
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* recently fund event */}
                            <Card style={{ backgroundColor: 'rgba(46,48,58,1)', border: 'none', marginTop: "1rem" }}>
                                <Card.Body>
                                    <div className="z2" style={{
                                        color: '#f59a32',
                                        marginBottom: '0.5rem',
                                    }}>FairMint Activity</div>
                                    {/* <span>0x0000...0000</span> sent <span>0.1</span> ethers */}
                                    {this.state.fundEvents.length > 0 ? <div>
                                        {
                                            this.state.fundEvents.map((event: FundEvent, index: number) => {
                                                return <div key={index} className="recentlyLogsItem z3" onClick={() => {
                                                    window.open(this.state.chain?.blockExplorer + '/tx/' + event.hash, '_blank');
                                                }}>
                                                    <div>{event.from.substring(0, 10) + '...' + event.from.substring(event.from.length - 8)}</div>  <div>{Number(ethers.formatEther(event.ethAmount + ''))} ETH</div>
                                                </div>
                                            })
                                        }
                                    </div> :
                                        <div>
                                            <img src={EmptyImage} alt="empty" style={{
                                                width: '10rem',
                                                height: '10rem',
                                                margin: '3rem auto',
                                                display: 'block',
                                            }} />
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer />
                {/* show qr modal */}
                <Modal show={this.state.showQR} onHide={() => {
                    this.setState({
                        showQR: false
                    });
                }}>
                    <Modal.Body>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{
                                    fontSize: '1.2rem',
                                    color: '#ffda6a',
                                    marginBottom: '1rem',
                                }}>{project?.symbol}'s CA QR code</div>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={QR + '?code=' + project?.address} alt="qr" />
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.8rem',
                            color: '#ffda6a',
                            marginTop: '1rem',
                        }}>
                            {project?.address}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showQR: false
                            });
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* amount input modal */}
                <Modal show={this.state.showAmountInput} onHide={() => {
                    this.setState({
                        showAmountInput: false
                    });
                }}>
                    <Modal.Body>
                        <div style={{
                        }}>
                            <Form.Label htmlFor="basic-url">
                                Enter the amount of ETH you want to pay for <span style={{ color: '#ffc107' }}>${project?.symbol}</span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="ETH amount"
                                    aria-label="ethers"
                                    aria-describedby="basic-addon2"
                                    type="number"
                                    value={this.state.amountInput + ""}
                                    max="100000"
                                    min="0.0001"
                                    isInvalid={Number(this.state.amountInput) < 0.0001}
                                    isValid={Number(this.state.amountInput) > 0.0001 && Number(this.state.amountInput) < 100000}
                                    onChange={(e) => {
                                        this.setState({
                                            amountInput: e.target.value
                                        });
                                    }}
                                />
                                <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAmountInput: false
                            });
                        }}>
                            Close
                        </Button>
                        <Button variant="warning" onClick={async () => {
                            this.doFund()
                        }}>
                            Send $ETH for ${project?.symbol}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* show alert */}
                <Modal centered show={this.state.showAlert}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                        <div>{this.state.alertMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAlert: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                {/* show confirm */}
                <Modal centered show={this.state.showConfirm}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                        <div>{this.state.confirmMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showConfirm: false,
                                onConfirm: undefined
                            });
                        }}>
                            Cancel
                        </Button>
                        <Button variant="warning" onClick={() => {
                            if (this.state.onConfirm !== undefined) {
                                this.state.onConfirm();
                            }
                        }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* show tx result */}
                <Modal centered show={this.state.showTxResult}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div>
                            {!this.state.txReceipt ? <img src={WaitingIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status === 1 ? <img src={SuccessIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status !== 1 ? <img src={ErrorIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                        </div>
                        {this.state.txHash ? <div>
                            Transaction hash: <a href={
                                this.state.chain?.blockExplorer + '/tx/' + this.state.txHash
                            } target="_blank" rel="noreferrer"> {this.state.txHash.substring(0, 10) + '...' + this.state.txHash.substring(this.state.txHash.length - 8)}</a>
                        </div> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showTxResult: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* show share, share is a dialog with project icon\ project name and symbol, and a url of this page with copy icon */}
                <Modal centered show={this.state.showShare}>
                    <Modal.Body>
                        {/* <div>
                            <img src={ShareIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} />
                        </div> */}
                        <div>Share it with your friends</div>
                        <div style={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}>
                            <img src={meta?.image ?? ''} alt="icon" style={{
                                width: '1rem',
                                height: '1rem',
                                marginRight: '0.5rem',
                                borderRadius: '50%',
                            }} />
                            {project?.name} ({project?.symbol})
                        </div>
                        <div>
                            <div className="z4" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '0.5rem',
                                marginTop: '2rem',
                                padding: '1rem',
                                backgroundColor: 'rgb(46, 48, 58)',
                                borderRadius: '0.5rem',
                            }}>
                                <div>${project?.symbol} has been launched on Rocket Protocol, {project?.rate} hot points now! {project && project.shortName?('https://dino.meme/' + project?.shortName) : window.location.href }</div>
                                <img src={CopyIcon} alt="copy" style={{
                                    width: '1.2rem',
                                    height: '1.2rem',
                                    cursor: 'pointer',
                                }} onClick={() => {
                                    copy(`$${project?.symbol} has been launched on Rocket Protocol, now ${project?.rate} hot. ${project && project.shortName?('https://dino.meme/' + project?.shortName) : window.location.href }`);
                                    this.alert('Copied to clipboard');
                                }} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showShare: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    toKMBT(num1: bigint | undefined, fixed: number = 3): string {
        if (num1 === undefined) {
            return '';
        }
        const str: string = ethers.formatEther(BigInt(num1));
        const num = Number(str);
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(fixed) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000 && num < 1000000000) {
            return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million
        } else if (num >= 1000000000) {
            return (num / 1000000000).toFixed(3) + 'B'; // convert to B for number from > 1 billion
        } else if (num <= 999) {
            return num.toFixed(fixed).toString(); // if value < 1000, nothing to do
        }
        return num.toString();
    }
}

export default DetailPage2;