import React from 'react';
import './Header.css';

import {  Button } from "react-bootstrap";
import { Chain, CurrentChain } from '../../config/chains';
import { API } from '../../config/api';
import { Project, ProjectMeta, ProjectMetaItem } from '../project/ProjectCard';
import { ethers } from 'ethers';

import Logo from '../../assets/logo.svg';
import MineIcon from '../../assets/image/profile.png';
import HelpIcon from '../../assets/image/a1.png';

interface HeaderProps {
    chain?: Chain;
    address: string;
    blocknumber?: number;
    showMessage: boolean;
    onConnectClick: () => void;
}

interface HeaderState {
    fundEvents: Array<FundEventItem>;
    project?: Project;
    [key: string]: any;
}

interface FundEventItem {
    project: Project;
    fundFrom: string;
    ethAmount: string;
    tokenAmount: string;
    hash: string;
    blockNumber: number;
    timestamp: number;
}

class HeaderDetail extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            fundEvents: [],
            project: undefined,

            fundEvent: undefined,
            animation: true,

            currentIndex: 0,
        };
    }

    componentDidMount() {
        if (this.props.showMessage) {
            this.loadFundEventsFromREST();
            this.loadDeployedProjectFromREST();

            setInterval(() => {
                this.setState({ animation: !this.state.animation });
                if (this.state.fundEvents.length > 0) {
                    const nextIndex = this.state.currentIndex + 1 >= this.state.fundEvents.length ? 0 : this.state.currentIndex + 1;
                    this.setState({
                        fundEvent: this.state.fundEvents[nextIndex],
                        currentIndex: nextIndex,
                    });
                }
            }, 1000);
        }
    }

    componentDidUpdate(prevProps: Readonly<HeaderProps>, prevState: Readonly<HeaderState>, snapshot?: any): void {
        if (prevProps.blocknumber !== this.props.blocknumber) {
            this.loadFundEventsFromREST();
            this.loadDeployedProjectFromREST();
        }
    }

    loadDeployedProjectFromREST = async () => {
        const url = `${API}/project/list?status=0&size=1`;
        const response = await fetch(url);
        const result = await response.json();

        if (result.code === 1) {
            const data = result.data.content;
            if (data.length > 0) {
                const project = data[0];
                this.setState({ project });
            }
        }
    }

    loadFundEventsFromREST = async () => {
        const url = `${API}/event/fundEvent?size=10`;
        const response = await fetch(url);
        const result = await response.json();
        if (result.code === 1) {
            const data = result.data.content;
            if (data.length > 0) {
                this.setState({ fundEvents: data, fundEvent: data.length > 0 ? data[0] : undefined });
            }
        }
    }

    parseMeta(meta: string): ProjectMeta | undefined {
        let obj: Array<ProjectMetaItem> = [];
        try {
            // data:application/json;base64,
            const _meta = meta.split(',')[1];

            obj = JSON.parse(
                atob(_meta)
            );

            // iterator obj , each element is formatted [{'traid_name': 'image', 'value': 'https://xxx'}, {'traid_name': 'youtube', 'value': 'https://xxx']
            let result: ProjectMeta = {};

            obj.forEach((element: ProjectMetaItem) => {
                result[element.trait_type] = element.value;
            });

            return result;

        } catch (error) {
            console.error(error);
        }

        return undefined;
    }

    formatDate(timestamp: number): string {
        // hh:mm:ss
        const date = new Date(timestamp);
        // to HH:mm:ss
        return date.toTimeString().substring(0, 8);
    }

    render() {
        const { chain, address, onConnectClick, } = this.props;
        const { fundEvent } = this.state;

        return <div><div style={{
            display: "flex",
            padding: "1rem",
            backgroundColor: "#2B2D38",
            color: "#ffffff",
        }}>
            <div style={{
                // maxWidth: "800px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
                width: "100%",
            }}>
                <div className="headerLeft">
                    {/* logo */}
                    <img src={Logo} alt="logo" style={{
                        width: "2.2rem",
                        height: "2.2rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                    }}
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    ></img>
                    <div className="mobile-hide" style={{
                        marginRight: '0.2rem',
                        cursor: "pointer",
                    }} onClick={() => {
                        window.location.href = '/';
                    }}>
                        <h3>Rocket</h3>
                    </div>
                    {this.state.fundEvent ? <div className={"headerPad1 mobile-hide " + (this.state.animation ? 'headerAnimation' : '')}>
                        {/* aaa fund 0.001 eth for bbb */}
                        {/* 滚动显示fund events */}
                        <div className="scroll-text">
                            <img src={this.parseMeta(fundEvent.project.meta)?.image} alt='icon' style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                marginRight: "0.5rem",
                                borderRadius: "50%",
                            }}>
                            </img><span>{
                                fundEvent.fundFrom.substring(0, 6) + '...' + fundEvent.fundFrom.substring(fundEvent.fundFrom.length - 4)
                            } pay {
                                    ethers.formatEther(fundEvent.ethAmount + '')
                                } ether for <a style={{ color: "#000" }} href={`/detail?address=${fundEvent.project.address}`}>${fundEvent.project.symbol}</a>
                                &nbsp;at {this.formatDate(fundEvent.timestamp)}
                            </span>
                        </div>
                    </div> : null}
                    {this.state.project ? <div className="headerPad2 mobile-hide">
                        {/* xxxxx created xxxx at xx:xx:xx */}
                        <div>
                            <img src={this.parseMeta(this.state.project.meta)?.image} alt='icon' style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                marginRight: "0.5rem",
                                borderRadius: "50%",
                            }}>
                            </img>
                            {this.state.project.creator.substring(0, 6) + '...' + this.state.project.creator.substring(this.state.project.creator.length - 4)} created&nbsp;
                            <a style={{ color: "#000" }} href={`/detail?address=${this.state.project.address}`}>{this.state.project.symbol}</a>
                            &nbsp;at {this.formatDate(this.state.project.createdAt)}
                        </div>
                    </div> : null}
                </div>
                <div className='z3' style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.2rem 0.5rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "#000",
                    }}>
                        {!chain ? <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <img src={CurrentChain.icon} alt="chain_logo" style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} ></img>
                            {CurrentChain.name}
                        </div>
                            : <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                {/* {chain.icon && <img alt="chain_logo" src={require('../assets/logo/' + chain.icon)} style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} ></img>} */}
                                <img alt="chain_logo" src={chain.icon} style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} ></img>
                                {chain.name}
                            </div>}
                    </div>
                    <div style={{ width: "1rem" }}></div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        {/* address prefix 6 and suffix 6 */}
                        {address ? <div className="z3" style={{
                            backgroundColor: "#1BB2DA",
                            color: "#ffffff",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.5rem",
                        }}>
                            {address.substring(0, 6) + '...' + address.substring(address.length - 4)}
                        </div> : <div>
                            <Button variant="info" size='sm' style={{backgroundColor:'#1bb2da', border:'none'}} onClick={onConnectClick}>Connect wallet</Button>
                        </div>}
                        {address ? 
                            <img src={MineIcon} alt="mine" style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                            }} onClick={() => {
                                window.location.href = '/mine';
                            }}></img>

                        : null}
                        <img src={HelpIcon} alt="help" style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                        }} onClick={() => {
                            window.location.href = 'https://rocketmeme.gitbook.io/en/gameplay';
                        }}></img>
                    </div>
                </div>
            </div>
        </div>
            <div className="showInMobilePhone">
                <div style={{
                    marginRight: "1rem",
                }}>
                    {this.state.fundEvent ? <div className={"headerPad1 " + (this.state.animation ? 'headerAnimation' : '')} style={{
                        fontSize: "0.7rem",
                    }}>
                        {/* aaa fund 0.001 eth for bbb */}
                        {/* 滚动显示fund events */}
                        <div className="scroll-text">
                            <img src={this.parseMeta(fundEvent.project.meta)?.image} alt='icon' style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                marginRight: "0.5rem",
                                borderRadius: "50%",
                            }}>
                            </img><span>{
                                fundEvent.fundFrom.substring(0, 6) + '...' + fundEvent.fundFrom.substring(fundEvent.fundFrom.length - 4)
                            } pay {
                                    ethers.formatEther(fundEvent.ethAmount + '')
                                } ETH for <a style={{ color: "#000" }} href={`/detail?address=${fundEvent.project.address}`}>${fundEvent.project.symbol}</a>
                                &nbsp;at {this.formatDate(fundEvent.timestamp)}
                            </span>
                        </div>
                    </div> : null}

                    {/*  */}

                    {this.state.project ? <div className="headerPad2" style={{
                        fontSize: "0.8rem",
                        marginLeft: "1rem",
                        marginTop: "0.5rem",
                    }}>
                        {/* xxxxx created xxxx at xx:xx:xx */}
                        <div>
                            <img src={this.parseMeta(this.state.project.meta)?.image} alt='icon' style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                marginRight: "0.5rem",
                                borderRadius: "50%",
                            }}>
                            </img>
                            <a style={{ color: "#000" }} href={`/detail?address=${this.state.project.address}`}>{this.state.project.symbol}</a> starts Fair Mint
                            &nbsp;at {this.formatDate(this.state.project.createdAt)}
                        </div>
                    </div> : null}

                </div>
            </div>
        </div>;
    }
}

function Header(props: HeaderProps) {
    return <HeaderDetail showMessage={props.showMessage} chain={props.chain} address={props.address} onConnectClick={props.onConnectClick} />;
}

export default Header;