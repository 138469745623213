import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import DetailPage from './pages/DetailPage';
import RedirectPage from './pages/RedirectPage';
import PortalPage from './pages/PortalPage';
import MinePage from './pages/MinePage';
import DetailPage2 from './pages/DetailPage2';
import FactoryPage from './pages/FactoryPage';
import ShortURLPage from './pages/ShortURLPage';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/list" element={<IndexPage />} />
      <Route path="/" element={<PortalPage />} />
      <Route path="/mine" element={<MinePage tab={new URLSearchParams(window.location.search).get("tab")} />} />
      <Route path="/detail" element={<DetailPage address={new URLSearchParams(window.location.search).get("address") ?? ''} />} 
      />
      {/* factory */}
      <Route path="/factory" element={<FactoryPage />} />
      {/* dt */}
      <Route path="/dt" element={<DetailPage2 projectAddress={new URLSearchParams(window.location.search).get("address") ?? ''} />} />
      <Route path="/redirect" element={<RedirectPage re={new URLSearchParams(window.location.search).get("re") ?? ""} />} />

      <Route path="/*" element={<ShortURLPage />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
