import { ethers, Provider, TransactionReceipt } from 'ethers';
import { Chain } from '../config/chains';

declare global {
    interface Window {
        ethereum: any;
        web3: any;
    }
}


interface ConnectResult {
    provider: any;
    chainId: bigint;
    address?: string;
    blocknumber?: number;
}


export const conn = async (): Promise<ConnectResult> => {
	let provider //, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
		const network = await provider.getNetwork()
		const chainId = network.chainId
		return {
			provider,
			chainId
		}
	} catch (error) {
		console.error(error)
		throw error
	}
}

export const isInWeb3Browser = (): boolean => {
	return window.ethereum != null || window.web3 != null
}

export const tryConnectSilent = async (): Promise<ConnectResult | null> => {
	let provider, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
	} catch (error) {
		console.error(error)
		throw error
	}
	try {
		res = await provider.listAccounts()
	} catch (error) {
		console.error(error)
		return null;
	}
	try {
		blocknumber = await provider.getBlockNumber()
	} catch (error) {
		console.error(error)
		return null;
	}

	const network = await provider.getNetwork()
	const chainId = network.chainId

	return {
		provider,
		blocknumber,
		chainId,
		address: res.length > 0 ? res[0].address : undefined
	};
}


// connect to the metamask or other web3 wallet with ethers.js
export const connect = async (): Promise<ConnectResult | null> => {
	let provider, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
	} catch (error) {
		// Dialog.confirm({ message: 'Please install Metamask Or open In Metamask/TokenPocket APP' }).then(() => {
		// 	location.href = 'https://metamask.io/'
		// })
		console.error(error)
		throw error
	}
	try {
		res = await provider.send('eth_requestAccounts', [])
	} catch (error) {
		console.error(error)
		return null;
	}
	try {
		blocknumber = await provider.getBlockNumber()
	} catch (error) {
		console.error(error)
		return null;
	}

	const network = await provider.getNetwork()
	const chainId = network.chainId

	return {
		provider,
		blocknumber,
		chainId,
		address: res[0]
	};
}


export function onAccountChange(success: Function) {
	// console.log(window.ethereum)
	window.ethereum.on('accountsChanged', async (accounts: any) => {
		success(accounts)
	})
}

export function onChainSwitch(success: Function) {
	window.ethereum.on('chainChanged', async (chainId: number | string) => {
		success(chainId)
	});
}


export async function switchNetwork(chain: Chain, callback: Function) {

	// decimal to hex
	const targetChainId = '0x' + chain.chainId.toString(16)

	window.ethereum && window.ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [
			{
				chainId: targetChainId
			}
		],

	}).then(() => {
		console.log('Switch success')
		if (typeof callback === 'function') {
			callback();
		}
	}).catch((e: { message: string; }) => {

		if (!window.confirm('Error: ' + e.message + ',Do you want to add ' + chain.name + ' into your network configuration?')) {
			return;
		}

		window.ethereum && window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [{
				chainId: targetChainId,
				chainName: chain.name,
				nativeCurrency: {
					name: chain.symbol,
					symbol: chain.symbol,
					decimals: chain.decimals
				},
				rpcUrls: chain.rpcUrl,
				blockExplorerUrls: [chain.blockExplorer]
			}]
		}).then(() => {
			console.log('network add success')
			alert('Network add successful');
			if (typeof callback === 'function') {
				callback();
			}
		}).catch((e: { message: string; }) => {
			console.error(e)
			alert('ERROR:' + e.message);
		})

	})
}



export function watchTxRecp(timer: number, txid: string, provider: Provider, success: Function,
    failed?: Function

 ) {
	if (timer) {
		clearInterval(timer)
	}
	timer = window.setInterval(() => {

		success = (recp: TransactionReceipt ) => {
			clearInterval(timer)
			success(recp)
		}

		getTxReceipt(
            txid,
            provider,
            success,
            failed
        )

	}, 800);
}

export function getTxReceipt(txid: string, provider: Provider,
    success: Function, failed?: Function, process?: Function, error?: Function

) {
	provider.getTransactionReceipt(txid).then((recp: TransactionReceipt | null) => {
		console.log("recp = ")
		console.log(recp)
		if (recp) {
			if (recp.status === 1) {
					success(recp);
			} else if (recp.status === 0) {
				if (typeof failed === 'function') {
					failed(recp);
				}
			}
		} else {
			if (typeof process === 'function') {
				process()
			}
		}
	}).catch(e => {
		if (typeof error === 'function') {
			error(e);
		}
	})
}

// to EIP55
export function toEIP55(address: string | null | undefined) {
    if (!address) {
        return ''
    }
    console.log('address:', address)
    return ethers.getAddress(address)
}