import React from "react";

import Header from "../components/header/Header";
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, switchNetwork, toEIP55, tryConnectSilent, watchTxRecp } from "../utils/dapp";
import { Contract, ethers, Provider, TransactionReceipt, TransactionResponse } from "ethers";
import { CHAINS, CurrentChain } from "../config/chains";

import "./DetailPage.css";
import { Badge, Button, Card, Col, Container, Form, InputGroup, Modal, ProgressBar, Row } from "react-bootstrap";
import { Project, ProjectMeta, ProjectMetaItem } from "../components/project/ProjectCard";
import { API, QR } from "../config/api";
import CircleTimer from "../components/timer/CircleTimer";
import { IFairLaunchTokenABI } from "../abi/IFairLaunchToken.abi";

import copy from 'copy-to-clipboard';
import { doClaimETH, doFund, doMint, doRefund, doStart } from "../utils/fairlaunch";

import CopyIcon from '../assets/icon/copy.png';
import InformationIcon from '../assets/icon/information.png';
import ErrorIcon from '../assets/icon/error.png';
import SuccessIcon from '../assets/icon/success.png';
import WaitingIcon from '../assets/icon/waiting.png';
import Footer from "../components/footer/Footer";
import TradeWidget from "../components/trader/TradeWidget";
import BadgeTimer from "../components/timer/BadgeTimer";

import XIcon from '../assets/icon/x.png';
import YoutubeIcon from '../assets/icon/youtube.png';
import TelegramIcon from '../assets/icon/telegram.png';
import GitbookIcon from '../assets/icon/gitbook.png';
import WebsiteIcon from '../assets/icon/website.png';
import DiscordIcon from '../assets/icon/discord.png';

interface DetailPageDetailState {
  chain?: any;
  address?: string;
  blocknumber?: number;
  provider?: Provider;
  project?: Project;
  meta?: ProjectMeta;
  status: ProjectStatus;

  untilBlockNumber?: number;
  fundBalanceOf: bigint;
  mightGet: bigint;
  getExtraETH?: bigint;
  started?: boolean;
  canStart?: boolean;
  minted?: boolean;
  claimed?: boolean;

  totalEthers: bigint;
  amountInput: string;

  fundEvents: Array<FundEvent>;

  txReceipt?: TransactionReceipt | null;
  projectOwner: string;
  [propName: string]: any;
}

interface DetailPageDetailProps {
  projectAddress: string;
}


interface FundEvent {
  hash: string;
  from: string;
  ethAmount: string;
  blockNumber: number;

}

enum ProjectStatus {
  FUNDING = 0,
  PENDING_START = 1,
  LAUNCHED = 2,
}

class DetailPageDetail extends React.Component<DetailPageDetailProps, DetailPageDetailState> {

  constructor(props: DetailPageDetailProps) {
    super(props);
    this.state = {
      chain: undefined,
      address: undefined,
      blocknumber: 0,
      provider: undefined,
      project: undefined,
      meta: undefined,
      status: ProjectStatus.FUNDING,

      untilBlockNumber: undefined,
      fundBalanceOf: BigInt(0),
      mightGet: BigInt(0),
      claimed: undefined,
      getExtraETH: undefined,
      started: false,
      canStart: false,
      minted: false,
      totalEthers: BigInt(0),

      showAmountInput: false,
      showAlert: false,
      showConfirm: false,
      onConfirm: Function,
      confirmMessage: '',
      txHash: null,
      timer: null,

      amountInput: '',
      alertMessage: '',

      showTxResult: false,
      txReceipt: undefined,
      projectOwner: '',

      fundEvents: [],
    };
  }

  componentDidMount() {
    this.initSilent();
    this.loadProject();
    this.loadFundEvents();
  }

  loadProject = async () => {
    // fetch 
    const url = `${API}/project/detail/${this.props.projectAddress}`;
    const res = await fetch(url);
    const data = await res.json();
    if (data.code === 1) {
      this.setState({
        project: data.data,
        meta: this.parseMeta(data.data.meta)
      });
    }

  }

  loadFundEvents = async () => {
    const url = `${API}/event/fundEvent/project/${this.props.projectAddress}`;
    const res = await fetch(url);
    const data = await res.json();
    if (data.code === 1) {
      this.setState({
        fundEvents: data.data.content
      });
    }
  }

  initSilent = async () => {
    if (!isInWeb3Browser()) {
      console.error('not in web3 browser');
      return;
    }
    // try silent connect first
    const res = await tryConnectSilent();
    if (res) {

      const chain = CHAINS[Number(res.chainId)];
      if (!chain) {
        console.error('chain not supported', res.chainId);
        return;
      }

      this.setState({
        provider: res.provider,
        blocknumber: res.blocknumber,
        chain: chain
      });

      if (res.address) {
        this.setState({
          address: res.address,
        });
      }

      this.watchChange();
      this.initTimer(chain, res.provider);
      this.loadProjectStatus(res.provider);
      this.loadProjectLaunchParams(res.provider);
      if (res.address) {
        this.looadAddressStatus(res.address, res.provider);
      }
    }
  };

  init = async () => {
    // try connect with popup
    if (!isInWeb3Browser()) {
      console.error('not in web3 browser');
      this.alert('Please use a web3 browser');
      return;
    }

    const res = await connect();
    if (res) {
      const chain = CHAINS[Number(res.chainId)];
      if (!chain) {
        console.error('chain not supported', res.chainId);
        // this.alert('Chain not supported');
        switchNetwork(CurrentChain, () => {
          this.init()
        });
        return;
      }

      this.setState({
        provider: res.provider,
        blocknumber: res.blocknumber,
        chain: chain,
        address: res.address,
      });
      this.watchChange();
      if (res.address) {
        this.looadAddressStatus(res.address, res.provider);
      }
    }
  }

  loadProjectStatus = async (provider: Provider) => {

    const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
    // started
    const started = await contract.started();

    // canStart
    const canStart = await contract.canStart();

    // totalEthers
    const totalEthers = await contract.totalEthers();

    // projectOwner
    const projectOwner = await contract.projectOwner();

    this.setState({
      started: started,
      canStart: canStart,
      totalEthers: totalEthers,
      projectOwner: projectOwner
    });
  }

  alert = (message: string) => {
    this.setState({
      showAlert: true,
      alertMessage: message
    });
  }

  looadAddressStatus = async (address: string, provider: Provider) => {
    const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
    const getExtraETH = await contract.getExtraETH(address);
    const claimed = await contract.claimed(address);
    const mightGet = await contract.mightGet(address);
    const fundBalanceOf = await contract.fundBalanceOf(address);
    // minted
    const minted = await contract.minted(address);

    this.setState({
      getExtraETH: getExtraETH,
      claimed: claimed,
      mightGet: mightGet,
      fundBalanceOf: fundBalanceOf,
      minted: minted,
    });

  }

  loadProjectLaunchParams = async (provider: Provider) => {
    // untilBlockNumber
    const contract = new Contract(this.props.projectAddress, IFairLaunchTokenABI, provider);
    const untilBlockNumber = await contract.untilBlockNumber();
    this.setState({
      untilBlockNumber: untilBlockNumber
    });

  }

  watchChange = async (): Promise<void> => {
    onAccountChange((accounts: any) => {
      this.setState({
        address: toEIP55(accounts[0])
      })
    })

    onChainSwitch((chainId: number | string) => {
      console.log('chainId:', chainId)
      this.initSilent();
    })
  }

  initTimer = (chain: any, provider: Provider): void => {
    const that = this

    provider.on('block', async (blockNumber: number) => {
      that.setState({
        blocknumber: blockNumber
      });

      that.loadProjectStatus(provider);
      that.loadProject()
      that.loadFundEvents();
    })

  }

  parseMeta(meta: string): ProjectMeta | undefined {
    let obj: Array<ProjectMetaItem> = [];
    try {
      // data:application/json;base64,
      const _meta = meta.split(',')[1];
      obj = JSON.parse(
        atob(_meta)
      );
      let result: ProjectMeta = {};
      obj.forEach((element: ProjectMetaItem) => {
        result[element.trait_type] = element.value;
      });
      return result;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  doFund = async () => {
    const tx = await doFund(this.props.projectAddress, ethers.parseEther(this.state.amountInput))

    this.setState({
      showAmountInput: false,
      showTxResult: true,
      txHash: tx?.hash
    });
    const that = this
    if (tx && tx.hash && this.state.provider) {
      watchTxRecp(this.state.timer, tx.hash, this.state.provider, (recp: any) => {
        that.setState({
          timer: null,
          txReceipt: recp,
        });
      })
    }
  }

  doRefund = async () => {
    const that = this

    that.setState({
      showConfirm: true,
      confirmMessage: 'Are you sure to refund?',
      onConfirm: async () => {
        const tx = await doRefund(that.props.projectAddress);
        that.setState({
          showConfirm: false,
          onConfirm: undefined,
          txHash: tx?.hash,
          showTxResult: true
          ,
        });

        if (tx && tx.hash && that.state.provider) {
          watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
            that.setState({
              timer: null,
              txReceipt: recp
            });
          })
        }
      }
    });
  }

  doLaunch = async () => {
    const that = this

    that.setState({
      showConfirm: true,
      confirmMessage: 'Are you sure to launch?',
      onConfirm: async () => {
        const tx = await doStart(that.props.projectAddress);
        that.setState({
          showConfirm: false,
          onConfirm: undefined,
          txHash: tx?.hash,
          showTxResult: true
          ,
        });

        if (tx && tx.hash && that.state.provider) {
          watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
            that.setState({
              timer: null,
              txReceipt: recp
            });
          })
        }
      }
    });
  }

  doClaim = async () => {
    const that = this

    that.setState({
      showConfirm: true,
      confirmMessage: 'Are you sure to claim?',
      onConfirm: async () => {
        const tx = await doMint(that.props.projectAddress);
        that.setState({
          showConfirm: false,
          onConfirm: undefined,
          txHash: tx?.hash,
          showTxResult: true
          ,
        });

        if (tx && tx.hash && that.state.provider) {
          watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
            that.setState({
              timer: null,
              txReceipt: recp
            });
          })
        }
      }
    });
  }

  doClaimETH = async () => {
    const that = this

    that.setState({
      showConfirm: true,
      confirmMessage: 'Are you sure to claim extra eth?',
      onConfirm: async () => {
        const tx = await doClaimETH(that.props.projectAddress);
        that.setState({
          showConfirm: false,
          onConfirm: undefined,
          txHash: tx?.hash,
          showTxResult: true
          ,
        });

        if (tx && tx.hash && that.state.provider) {
          watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
            that.setState({
              timer: null,
              txReceipt: recp
            });
          })
        }
      }
    });
  }

  render() {
    const { project, meta } = this.state;
    return <div >
      <div className="detailLarge">
        <Header showMessage={true} chain={this.state.chain} onConnectClick={() => {
          this.init();
        }} address={this.state.address ?? ""} />
        <div>
          <div className="detailBackButton"><span onClick={() => {
            document.location.href = '/';
          }}>[go back]</span></div>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={8} md={8} sm={12}>
                <Card border="warning">
                  <Card.Body>
                    {project ? <Card.Title style={{
                      display: 'flex',
                      alignItems: 'center',
                      wordBreak: 'break-all'
                    }}>
                      {/* logo circle */}
                      <img src={meta?.image} alt={project.name} style={{ width: '1.2rem', borderRadius: '50%', marginRight: '0.6rem' }} />

                      {project.address}

                      {/* copy icon */}
                      <img src={CopyIcon} alt="copy" style={{ width: '1rem', marginLeft: '1rem', cursor: 'pointer' }} onClick={() => {
                        copy(project.address);
                        this.alert('address copied');
                      }} />
                    </Card.Title> : null}
                  </Card.Body>
                </Card>
                <div>
                  <div className="detailSocialInfo">
                    {meta?.x ? <div className="detailSocial"
                      onClick={() => {
                        window.open(meta?.x, '_blank');
                      }}
                    >[twitter]</div> : null}
                    {meta?.telegram ? <div className="detailSocial" onClick={
                      () => {
                        window.open(meta?.telegram, '_blank');
                      }

                    }>[telegram]</div> : null}
                    {meta?.website ? <div className="detailSocial" onClick={
                      () => {
                        window.open(meta?.website, '_blank');
                      }
                    }

                    >[website]</div> : null}
                    {meta?.github ? <div className="detailSocial" onClick={
                      () => {
                        window.open(meta?.github, '_blank');
                      }

                    }>[github]</div> : null}
                    {meta?.gitbook ? <div className="detailSocial" onClick={() => {
                      window.open(meta?.gitbook, '_blank');

                    }}>[gitbook]</div> : null}
                    {meta?.youtube ? <div className="detailSocial" onClick={() => {
                      window.open(meta?.youtube, '_blank');
                    }}>[youtube]</div> : null}
                  </div>
                </div>

                <div className="detailProjectInfo">
                  <div className="detailProjectInfoTitle">
                    <div>
                      <Badge bg="warning" text="dark" style={{ marginRight: '1rem' }}>
                        {project ? project.creator.substring(0, 6) + '...' + project.creator.substring(project.creator.length - 4) : ''}
                      </Badge>
                      {project ? new Date(project.createdAt).toUTCString() : ''}
                    </div>

                    <div>
                      {!this.state.started && !this.state.canStart ? <Badge bg="warning" text="dark">
                        available
                      </Badge> : null}

                      {!this.state.started && this.state.canStart ? <Badge bg="success">
                        pending launch
                      </Badge> : null}

                      {this.state.started ? <Badge bg="secondary">
                        launched
                      </Badge> : null}
                    </div>
                  </div>
                  <div className="detailProjectBody">
                    <img src={meta?.image} alt={project?.name} />
                    <div>
                      {/* created by  */}
                      <div className="detailProjectInfoMeta hightlight">
                        Created by {project?.creator.substring(0, 6) + '...' + project?.creator.substring(project?.creator.length - 4)}
                      </div>
                      <div className="detailProjectInfoMeta">
                        {/* token name and ticker */}
                        {project?.name} (ticker: {project?.symbol})
                      </div>
                      <div className="detailProjectInfoMeta ">
                        {/* totalSupply */}
                        Total supply: <b style={{ color: '#0dcaf0' }}>{Number(ethers.formatEther(project ? BigInt(project.totalSupply) : '0'))} {project?.symbol}</b>
                      </div>
                      <div className="detailProjectInfoDescription">
                        {meta?.description}
                      </div>
                    </div>
                  </div>


                </div>
                {/* fund progress */}
                <div>
                  {project ? <div className="detailProgressTitle">
                    funds raised:
                    <span style={{ color: '#ffda6a', marginLeft: '1rem' }}>{
                      Number(ethers.formatEther(this.state.totalEthers + ''))
                    } / {
                        Number(ethers.formatEther(project.softCap + ''))
                      } ETH
                      ({
                        Number(this.state.totalEthers * BigInt(100) / BigInt(project.softCap)) + '%'
                      })</span></div> : null}
                  {project ? <ProgressBar variant="warning" now={
                    Number(this.state.totalEthers * BigInt(100) / BigInt(project.softCap))
                  } /> : null}
                  <div>
                    {/* 当募资超过softCap后， 多余的资金会根据投资比例返还给玩家 */}
                  </div>
                </div>
                {/* rules */}
                <div className="detailPageRulesTitle">How to play</div>
                <div className="detailPageRulesBody">
                  <div>Transfer ETH to CA directly.</div>
                  <div>
                    Wait for the countdown to end and launch the trading.
                  </div>
                  <div>
                    Transfer <b style={{ color: '#ffc107' }}>0.0001 ETH</b> to CA directly to claim your tokens.
                  </div>
                  <div>
                    If the funds raised exceed the MAX LP required, you can claim the extra ETH by send <b style={{ color: '#ffc107' }}>0.0002 ETH</b> to CA after the project is launched.
                  </div>
                </div>
                <div className="detailPageRulesTitle">How to refund</div>
                <div className="detailPageRulesBody">
                  <div>
                    If the project is not launched, you can refund the ETH you funded.
                  </div>
                  <div>
                    You can refund by sending <b style={{ color: '#ffc107' }}>0.0002 ETH</b> to the project address. (-6% service fee deducted)
                  </div>
                </div>

                <div className="detailPageRulesTitle">Alert</div>
                <div className="detailPageRulesBody">
                  <div className="detailPageRulesBodyWarning">
                    <img src={ErrorIcon} style={{
                      width: '1rem',
                      marginRight: '0.5rem'
                    }} alt="error">
                    </img>
                    Please don't directly withdraw ETH from CEX to the token contract, it may cause the loss of your assets.
                  </div>
                  <div style={{ height: '1rem' }}></div>
                  <div className="detailPageRulesBodyWarning">
                    <img src={ErrorIcon} style={{
                      width: '1rem',
                      marginRight: '0.5rem'
                    }} alt="error">
                    </img>
                    Please confirm the main network of the token. If you send the token to the wrong network, you may lose your assets.
                  </div>
                </div>
                <div style={{ height: '1rem' }}>

                </div>
              </Col>
              <Col lg={4} md={4} sm={12}>
                {this.state.untilBlockNumber && this.state.blocknumber ? <CircleTimer seconds={
                  Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? (Number(this.state.untilBlockNumber) - Number(this.state.blocknumber)) * this.state.chain.blockSeconds : 0
                } blocks={
                  Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) : 0
                } canStart={
                  this.state.canStart ? true : false
                } started={
                  this.state.started ? true : false

                } /> : null}
                {/* trading area */}

                {this.state.started ? <Card style={{ backgroundColor: 'rgba(46,48,58,1)' }}><Card.Body><TradeWidget
                  projectAddress={this.props.projectAddress}
                  symbol={project?.symbol}
                  logo={meta?.image}
                  chainId={this.state.chain?.chainId}
                  provider={this.state.provider}
                  address={this.state.address}
                  blockNumber={this.state.blocknumber}
                  onSwapFail={(error: Error) => {
                    console.error(error)
                    this.alert(error.message);
                  }}
                  onSwapSuccess={async (tx: TransactionResponse) => {
                    const that = this
                    if (tx && tx.hash && that.state.provider) {
                      watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                          timer: null,
                          txReceipt: recp
                        });
                      })
                    }
                    this.setState({
                      txHash: tx.hash,
                      showTxResult: true
                    });
                  }}
                ></TradeWidget></Card.Body></Card> : null}
                {/* 我的信息区域 */}
                <div style={{ marginTop: '1rem' }}>
                  <div >
                    {this.state.address ? <p>{this.state.address?.substring(0, 6) + '...' + this.state.address?.substring(this.state.address.length - 4)} sent {Number(Number(ethers.formatEther(this.state.fundBalanceOf + '')).toFixed(3))} ethers. </p> : null}
                    {this.state.started || this.state.canStart ? <p>Estimated {Number(Number(ethers.formatEther(this.state.mightGet + '')).toFixed(3))} {project?.symbol}</p> : null}
                  </div>
                </div>
                {/* 操作和状态*/}
                <div style={{ marginTop: '1rem' }}>
                  <Card style={{ backgroundColor: 'rgba(46,48,58,1)' }}>
                    <Card.Body>
                      <div className="d-grid gap-2">
                        {this.state.started && !this.state.minted ? <Button variant="outline-warning"
                          onClick={() => {
                            this.doClaim()
                          }}
                        >
                          Claim tokens
                        </Button> : null}
                        {this.state.started && this.state.getExtraETH && this.state.getExtraETH > 0 && !this.state.claimed ? <Button variant="outline-warning" onClick={() => {
                          this.doClaimETH()
                        }} >
                          Claim extra ETH
                        </Button> : null}
                        {!this.state.started && !this.state.canStart ? <Button variant="outline-warning"
                          onClick={() => {
                            this.setState({
                              showAmountInput: true
                            });
                          }}
                        >
                          Fund
                        </Button> : null}
                        {!this.state.started ? <Button variant="outline-secondary" onClick={() => {
                          this.doRefund()
                        }}>
                          Refund
                        </Button> : null}
                        {!this.state.started && this.state.canStart ? <Button variant="outline-warning" onClick={() => {
                          this.doLaunch()
                        }} >
                          Launch
                        </Button> : null}
                        {!this.state.address ? <Button variant="primary" onClick={() => {
                          this.init();
                        }}>
                          Connect
                        </Button> : null}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                {/* qrcode */}
                <div style={{
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <img src={QR + '?code=' + this.props.projectAddress} alt="qrcode" style={{ width: '60%', marginTop: '1rem' }} />
                </div>

                {/* recently fund event */}
                <div className="recentlyLogs">
                  {/* <span>0x0000...0000</span> sent <span>0.1</span> ethers */}
                  {
                    this.state.fundEvents.map((event: FundEvent, index: number) => {
                      return <div key={index} className="recentlyLogsItem" onClick={() => {
                        window.open(this.state.chain?.blockExplorer + '/tx/' + event.hash, '_blank');
                      }}>
                        <div>{event.from.substring(0, 10) + '...' + event.from.substring(event.from.length - 8)}</div>  <div>{Number(ethers.formatEther(event.ethAmount + ''))} ethers</div>
                      </div>
                    })
                  }
                </div>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
        {/* amount input modal */}
        <Modal show={this.state.showAmountInput} onHide={() => {
          this.setState({
            showAmountInput: false
          });
        }
        }>
          <Modal.Body>
            <div style={{
            }}>
              <Form.Label htmlFor="basic-url">
                Enter the amount of ETH you want to pay for <span style={{ color: '#ffc107' }}>${project?.symbol}</span>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="ETH amount"
                  aria-label="ethers"
                  aria-describedby="basic-addon2"
                  type="number"
                  value={this.state.amountInput + ""}
                  max="100000"
                  min="0.0001"
                  isInvalid={Number(this.state.amountInput) < 0.0001}
                  isValid={Number(this.state.amountInput) > 0.0001 && Number(this.state.amountInput) < 100000}
                  onChange={(e) => {
                    this.setState({
                      amountInput: e.target.value
                    });
                  }}
                />
                <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              this.setState({
                showAmountInput: false
              });
            }}>
              Close
            </Button>
            <Button variant="warning" onClick={async () => {
              this.doFund()
            }}>
              Send $ETH for ${project?.symbol}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show alert */}
        <Modal centered show={this.state.showAlert}>
          <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
            <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
            <div>{this.state.alertMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              this.setState({
                showAlert: false
              });
            }}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>
        {/* show confirm */}
        <Modal centered show={this.state.showConfirm}>
          <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
            <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
            <div>{this.state.confirmMessage}</div>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="secondary" onClick={() => {
              this.setState({
                showConfirm: false,
                onConfirm: undefined
              });
            }}>
              Cancel
            </Button>
            <Button variant="warning" onClick={() => {
              if (this.state.onConfirm !== undefined) {
                this.state.onConfirm();
              }
            }}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show tx result */}
        <Modal centered show={this.state.showTxResult}>
          <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
            <div>
              {!this.state.txReceipt ? <img src={WaitingIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
              {this.state.txReceipt && this.state.txReceipt.status === 1 ? <img src={SuccessIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
              {this.state.txReceipt && this.state.txReceipt.status !== 1 ? <img src={ErrorIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
            </div>
            {this.state.txHash ? <div>
              Transaction hash: <a href={
                this.state.chain?.blockExplorer + '/tx/' + this.state.txHash
              } target="_blank" rel="noreferrer"> {this.state.txHash.substring(0, 10) + '...' + this.state.txHash.substring(this.state.txHash.length - 8)}</a>
            </div> : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              this.setState({
                showTxResult: false
              });
            }}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>
      </div>

      {/* small media */}
      <div className="detailSmall">
        <Header showMessage={true} chain={this.state.chain} onConnectClick={() => {
          this.init();
        }} address={this.state.address ?? ""} />
        <div className="detailSmallContent">
          {/* project image */}
          <Row>
            <img src={meta?.image} alt={project?.name} style={{
              width: '8rem',
            }} />
            <Col>
              <div className="detailProjectTitle">
                {project?.name} ({project?.symbol})
              </div>
              {/* description */}
              <div className="detailProjectDescription" style={{
                fontSize: '0.8rem'
              }}>
                {meta?.description}
              </div>
              <div style={{
                fontSize: '0.8rem'
              }}>
                Funds raised: {project ? <span style={{ color: '#ffda6a', }}>{
                  Number(ethers.formatEther(this.state.totalEthers + ''))
                }  / {this.state.chain?.symbol ?? ""}
                  ({
                    Number(this.state.totalEthers * BigInt(100) / BigInt(project.softCap)) + '%'
                  })</span> : null}
              </div>
              <div style={{
                fontSize: '0.8rem'
              }}>
                Participants: <span
                  style={{
                    color: '#ffda6a'
                  }}
                >
                  {this.state.project?.funders}
                </span>
              </div>
            </Col>
          </Row>
          <div>
            <div style={{
              marginTop: '1rem',
              display: 'flex',
              alignContent: 'center',
              gap: '1rem'
            }}>
              FairMint <BadgeTimer bg="success"
                seconds={
                  Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? (Number(this.state.untilBlockNumber) - Number(this.state.blocknumber)) * this.state.chain.blockSeconds : 0
                } blocks={
                  Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) > 0 ? Number(this.state.untilBlockNumber) - Number(this.state.blocknumber) : 0
                } canStart={
                  this.state.canStart ? true : false
                } started={
                  this.state.started ? true : false}
              ></BadgeTimer>
            </div>
            <div className="smallContent">Send ETH to HD's contract address to participate in FairMint</div>
            <div className="smallContent">CA: <img src={this.state.chain?.icon} alt={this.state.chain?.name} style={{ width: '0.8rem', marginRight: '0.2rem' }} />{this.state.project?.address}
              {/* copy image */}
              <img src={CopyIcon} alt="copy" style={{ width: '1rem', marginLeft: '0.2rem', cursor: 'pointer' }} onClick={() => {
                copy(this.state.project?.address ?? "");
                this.alert('address copied');
              }} />
            </div>
          </div>
          <div>
            <div className="smallTitle">How to FairMint?</div>
            <div className="smallContent">
              <div><Badge>1</Badge> Transfer $ETH from your wallet to CA.</div>
              <div><Badge>2</Badge> Wait FairMint ends and trading starts. </div>
              <div><Badge>3</Badge> Transfer 0.0001 ETH to CA to claim token. </div>
              <div><Badge>4</Badge> If funds raised exceed LP Max Limit. Transfer 0.0002 ETH to CA to claim remaining ETH. </div>
            </div>
          </div>

          <div>
            <div className="smallTitle">How to refund before Fair Mint ends? </div>
            <div className="smallContent">
              Before trading starts, transfer 0.0002 ETH to CA to refund ETH (-6% service fee deducted)
            </div>
            <a href="https://rocket.meme">Fairmint Guide &gt; </a>
          </div>

          <div className="warningPanel">
            <div className="warningPanelRow">
              <div><Badge bg="danger">!</Badge></div>
              <div>Please do not directly withdraw ETH from CEX to the token contract address; you must use your wallet to send ETH! </div>
            </div>
            <div className="warningPanelRow">
              <div><Badge bg="danger">!</Badge></div>
              <div>
                Please confirm the main network of the token. If the main network is incorrect, the ETH will be lost!.
                transfer 0.0002 ETH to CA to refund ETH (-6% service fee deducted)
              </div>
            </div>
          </div>

          <div>
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Media</div>
              <div className="smallInformationValue">
                {meta?.telegram ? <img src={TelegramIcon} alt="info" onClick={() => {
                  if (meta?.telegram) {
                    document.location.href = meta?.telegram
                  }
                }} /> : null}

                {/* X */}
                {meta?.x ? <img src={XIcon} alt="info" onClick={() => {
                  if (meta?.x) {
                    document.location.href = meta?.x
                  }
                }} /> : null}

                {/* discord */}
                {meta?.discord ? <img src={DiscordIcon} alt="info" onClick={() => {
                  if (meta?.discord) {
                    document.location.href = meta?.discord
                  }
                }} /> : null}

                {/* youtube */}
                {meta?.youtube ? <img src={YoutubeIcon} alt="info" onClick={() => {
                  if (meta?.youtube) {
                    document.location.href = meta?.youtube
                  }
                }} /> : null}

                {/* gitbook */}
                {meta?.gitbook ? <img src={GitbookIcon} alt="info" onClick={() => {
                  if (meta?.gitbook) {
                    document.location.href = meta?.gitbook
                  }
                }} /> : null}


                {/* website */}
                {meta?.website ? <img src={WebsiteIcon} alt="info" onClick={() => {
                  if (meta?.website) {
                    document.location.href = meta?.website
                  }
                }} /> : null}
              </div>
            </div>
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Blockchain</div>
              <div className="smallInformationValue">
                <img src={this.state.chain?.icon} alt={this.state.chain?.name} />
                <div>
                  {this.state.chain?.name}
                </div>
              </div>
            </div>
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Total supply</div>
              <div className="smallInformationValue">
                <img src={meta?.image} alt={this.state.chain?.name} />
                {project ? <div>
                  {Number(ethers.formatEther(BigInt(project?.totalSupply))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {project?.symbol}
                </div> : null}
              </div>
            </div>

            {/* LP Maximum Limit */}
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">LP Maximum Limit</div>
              <div className="smallInformationValue">
                {project ? <div>
                  {Number(ethers.formatEther(BigInt(project?.totalSupply) / BigInt(2))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {project?.symbol}
                  &nbsp;/&nbsp;{Number(ethers.formatEther(BigInt(project?.softCap))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {this.state.chain?.symbol}
                </div> : null}
              </div>
            </div>

            {/* FairMint Ending Block */}
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Ending Block</div>
              <div className="smallInformationValue">
                #{this.state.untilBlockNumber + ''}
              </div>
            </div>

            {/* creator */}
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Creator</div>
              <div className="smallInformationValue">
                {project?.creator.substring(0, 6) + '...' + project?.creator.substring(project?.creator.length - 4)}
              </div>
            </div>

            {/* creation time */}
            <div className="smallInfomationRow">
              <div className="smallInformationLabel">Creation Time</div>
              <div className="smallInformationValue">
                {new Date(project?.createdAt).toUTCString()}
              </div>
            </div>
          </div>

          {/* recently fund event */}
          {this.state.fundEvents.length > 0 ? <div className="recentlyLogs">
            {/* <span>0x0000...0000</span> sent <span>0.1</span> ethers */}
            {
              this.state.fundEvents.map((event: FundEvent, index: number) => {
                return <div key={index} className="recentlyLogsItem" onClick={() => {
                  window.open(this.state.chain?.blockExplorer + '/tx/' + event.hash, '_blank');
                }}>
                  <div>{event.from.substring(0, 10) + '...' + event.from.substring(event.from.length - 8)}</div>  <div>{Number(ethers.formatEther(event.ethAmount + ''))} ethers</div>
                </div>
              })
            }
          </div> : null }
        </div>
        <Footer />
      </div>
      {/* end of small media */}
    </div>;
  }
}

interface DetailPageProps {
  address: string;
}

function DetailPage(props: DetailPageProps) {
  return <DetailPageDetail projectAddress={props.address} />;
}

export default DetailPage;