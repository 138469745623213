import React from "react";
import Header from "../components/header/Header";
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, toEIP55, tryConnectSilent } from "../utils/dapp";
import { Chain, CHAINS, CurrentChain } from "../config/chains";
import { API } from "../config/api";
import { Provider } from "ethers";

import CoverImage from '../assets/image/cover1.png';
import './PortalPage.css';

// import { SiGitbook } from "react-icons/si"
// import { BsTelegram, BsTwitterX } from "react-icons/bs"
import ProjectCard, { Project } from "../components/project/ProjectCard";
import { Col } from "react-bootstrap";
import Footer from "../components/footer/Footer";
// import PieChartImage from '../assets/image/pie.png';
import RightArrowIcon from '../assets/icon/rightarrow.svg';
// import TestImage from '../assets/image/test.jpg';

import RoadMapImage1 from '../assets/image/roadmap1.png';
import RoadMapImage2 from '../assets/image/roadmap2.png';
import RoadMapImage3 from '../assets/image/roadmap3.png';
import RoadMapImage4 from '../assets/image/roadmap4.png';
import RoadMapImage5 from '../assets/image/roadmap5.png';
import RoadMapImage6 from '../assets/image/roadmap6.png';

import FairImage from '../assets/image/fair.png';
import SecureImage from '../assets/image/secure.png';
import FunImage from '../assets/image/fun.png';

import GitbookImage from '../assets/image/gitbook-1.png';
import TelegramImage from '../assets/image/telegram-1.png';
import XImage from '../assets/image/x-1.png';

import Step1Image from '../assets/image/step1.png';
import Step2Image from '../assets/image/step2.png';
import Step3Image from '../assets/image/step3.png';
import Step4Image from '../assets/image/step4.png';
import Step5Image from '../assets/image/step5.png';

interface PortalPageState {
    blockNumber: number;
    address: string;
    chain?: Chain;
    provider: Provider | null;
    projects: Array<Project>;
}

interface PortalPageProps {
}

class PortalPage extends React.Component<PortalPageProps, PortalPageState> {

    constructor(props: PortalPageProps) {
        super(props);
        this.state = {
            blockNumber: 0,
            address: '',
            chain: undefined,
            provider: null,
            projects: [],
        };
    }


    componentDidMount(): void {
        this.initSilent();
        this.loadProjects();
    }

    initNoWeb3Browser = async (): Promise<void> => {
        // fetch API + '/currentBlock'

        const res = await fetch(API + '/currentBlock');
        const result = await res.json();
        if (result.code === 1) {
            this.setState({
                blockNumber: result.data,
            });
        }
    }

    initSilent = async (): Promise<void> => {
        if (!isInWeb3Browser()) {
            this.initNoWeb3Browser();
            return;
        }
        // const result = await conn();
        const result = await tryConnectSilent();
        console.log('result = ', result)
        if (result === null) {
            return;
        }

        const { chainId, provider } = result;
        const numChainId = Number(chainId);

        if (CHAINS[numChainId] === undefined) {
            return;
        }

        const blockNumber = await provider.getBlockNumber();

        this.setState({
            chain: CHAINS[numChainId],
            provider: provider,
            blockNumber: blockNumber,
        });

        if (result.address !== undefined) {
            this.setState({
                address: toEIP55(result.address),
            });
        }

        console.log('conn block number:', blockNumber)
        this.initTimer(CHAINS[numChainId], provider);
        this.watchChange();
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.setState({
                chain: CHAINS[Number(chainId)],
            });
            this.initSilent();
            if (CHAINS[Number(chainId)] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }
        })
    }

    init = async (): Promise<void> => {
        if (!isInWeb3Browser()) {
            alert('Please install MetaMask.');
            return;
        }

        try {
            const result = await connect()

            if (result === null) {
                return;
            }

            const { chainId, address } = result;
            const numChainId = Number(chainId);

            if (CHAINS[numChainId] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }

            console.log('chainId:', chainId)
            this.setState({
                address: toEIP55(address),
                chain: CHAINS[numChainId],
                provider: result.provider
            });

            this.watchChange();

        } catch (error) {
            console.error(error)
        }
    }

    loadProjects = async (): Promise<void> => {
        const url = `${API}/project/list?page=0&sort=createdAt,desc&sort=rate,desc&size=3&status=0`;

        try {
            const response = await fetch(url);
            const result = await response.json();

            if (result.code === 1) {
                console.log(result.data);
                this.setState({
                    projects: result.data.content,
                });
            } else {
                alert('Failed to load data');
            }

        } catch (error) {
            console.error(error);
        }

    }

    initTimer = (chain: any, provider: Provider): void => {
        const that = this

        provider.on('block', async (blockNumber: number) => {
            that.setState({
                blockNumber: blockNumber,
            });
        })
    }

    render() {
        return <div>

            <div>
                <Header showMessage={false} blocknumber={this.state.blockNumber} chain={this.state.chain} onConnectClick={() => {
                    this.init();
                }} address={this.state.address} />
            </div>
            <div>
                <div className="portalMain">
                    <Col sm={1} lg={6} md={6}>
                        {/* <h1>Rocket Protocol</h1> */}
                        <div className="z1 bold" onClick={() => {
                            // document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                        }}>Rocket Protocol</div>
                        <div className="z2">The best fair launch protocol for MEME Coins</div>
                        <div style={{ marginTop: '2rem' }}></div>
                        <a className="z2" href="/factory" rel="start" style={{ color: '#1bb2da' }}>[start a new coin]</a>
                        <div className="portalMainIcons z3">
                            <div onClick={() => {
                                document.location.href = 'https://rocketmeme.gitbook.io/';
                            }}>
                                {/* <SiGitbook style={{ fontSize: '1.2rem' }} />  */}
                                <img src={GitbookImage} alt="gitbook" />
                                Gitbook</div>
                            <div onClick={() => {
                                document.location.href = 'https://x.com/rocketdotmeme';
                            }}>
                                {/* <BsTwitterX style={{ fontSize: '1.2rem' }} />  */}
                                <img src={XImage} alt="x" />
                                Twitter</div>
                            <div
                                onClick={() => {
                                    document.location.href = 'https://t.me/rocketdotmeme';
                                }}
                            >
                                {/* <BsTelegram style={{ fontSize: '1.2rem' }} />  */}
                                <img src={TelegramImage} alt="telegram" />
                                Telegram</div>
                        </div>
                        <div className="flex" style={{ gap: '1rem', marginTop: "0.6rem" }}>
                            <div className="z3 pointer" onClick={() => {
                                // if is mobile/ios/android
                                document.location.href = 'https://github.com/rocketprotocollab';
                            }}>[ Github ]</div>
                            {/* github */}
                            <div className="z3 pointer" onClick={() => {
                                document.location.href = 'https://rocketmeme.gitbook.io/en/audit';
                            }
                            }>[ Smart contract audit report ]</div>
                            {/* audit report */}
                        </div>
                    </Col>
                    <Col sm={1} lg={6} md={6}>
                        <div className="only-mobile" style={{ height: '2rem' }}></div>
                        <img src={CoverImage} alt="cover" />
                    </Col>
                </div>
                {/* end of main cover */}
                <div className="divide"></div>
                <div className="portalSegment">
                    <div className="flex justify-between align-center">
                        <div className="portalSegmentTitle">Some fresh MEME coins...</div>
                        <div className="z3 link" onClick={(e) => {
                            document.location.href = '/list';
                        }}>More...</div>
                    </div>
                    <div className="portalSegmentProjects">
                        {/* project cards */}
                        {this.state.projects.map((project, index) => {
                            return <Col sm={1} lg={4} md={4} key={index} ><ProjectCard project={project}
                                blockSeconds={CurrentChain.blockSeconds} blocknumber={this.state.blockNumber}
                                provider={this.state.provider} />
                            </Col>
                        })}
                        {/* end of project cards */}
                    </div>
                </div>
                {/* end of projects */}
                <div className="divide"></div>
                <div className="portalSegment only-pc">
                    <div className="portalSegmentTitle">Rocket's Gameplay</div>
                    <div className="portalSegmentProjects portalSegmentRoadmap ">
                        {/*  */}
                        <div className="portalRuleStep">
                            <div className="portalRuleStepTitle z4 normal-color">
                                STEP 1
                            </div>
                            <div className="portalRoadmapItem">
                                <div className="portalRoadmapItemImage">
                                    <img src={Step1Image} alt="" />
                                </div>
                                <div className="portalRoadmapItemInfo">
                                    <div className="z2" onClick={(e) => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/en/gameplay';
                                    }}>Launch Coin</div>
                                    <div className="z4 normal-color">Only 3 minutes</div>
                                </div>
                            </div>
                        </div>

                        <div className="portalRuleStep">
                            <div className="portalRuleStepTitle z4 normal-color">
                                STEP 2
                            </div>
                            <div className="portalRoadmapItem">
                                <div className="portalRoadmapItemImage">
                                    <img src={Step2Image} alt="" />
                                </div>
                                <div className="portalRoadmapItemInfo">
                                    <div className="z2" onClick={(e) => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/en/gameplay';
                                    }}>FairMint</div>
                                    <div className="z4 normal-color">
                                        <div>50% FairMint</div>
                                        <div>50% added to LP</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="portalRuleStep">
                            <div className="portalRuleStepTitle z4 normal-color">
                                STEP 3
                            </div>
                            <div className="portalRoadmapItem">
                                <div className="portalRoadmapItemImage">
                                    <img src={Step3Image} alt="" />
                                </div>
                                <div className="portalRoadmapItemInfo">
                                    <div className="z2" onClick={(e) => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/en/gameplay';
                                    }}>List on Uniswap</div>
                                    <div className="z4 normal-color">
                                        LP locked forever
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="portalRuleStep">
                            <div className="portalRuleStepTitle z4 normal-color">
                                STEP 4
                            </div>

                            <div className="portalRoadmapItem">
                                <div className="portalRoadmapItemImage">
                                    <img src={Step4Image} alt="" />
                                </div>
                                <div className="portalRoadmapItemInfo">
                                    <div className="z2" onClick={(e) => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/en/gameplay';
                                    }}>Claim coins</div>
                                    <div className="z4 normal-color">Participants claim coins</div>
                                </div>
                            </div>
                        </div>

                        <div className="portalRuleStep">
                            <div className="portalRuleStepTitle z4 normal-color">
                                STEP 5
                            </div>
                            <div className="portalRoadmapItem">
                                <div className="portalRoadmapItemImage">
                                    <img src={Step5Image} alt="" />
                                </div>
                                <div className="portalRoadmapItemInfo">
                                    <div className="z2" onClick={(e) => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/en/gameply';
                                    }}>Creators Earns revenue</div>
                                    <div className="z4 normal-color">LP transaction fee</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="portalSegment">
                    <div className="portalSegmentTitle">Adopting the Rocket Protocol will bring you...</div>
                    <div className="portalSegmentProjects">

                        <Col sm={12} lg={4} md={4} className="portalSegmentFeature">
                            <div className="portalSegmentItemImage">
                                <img src={FairImage} alt="" />
                            </div>
                            <div className="portalSegmentItemInfo">
                                <div className="z2 portalSegmentItemTitle pointer" onClick={() => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/advantages';
                                }}>Fair</div>
                                <div className="portalSegmentItemLi z3">
                                    - 50% FairMint - 50% added to LP
                                </div>
                                <div className="portalSegmentItemLi z3">
                                    - No pre-allocation
                                </div>
                                <div className="portalSegmentItemLi z3">
                                    - No front-running, no worries of bots snipers
                                </div>
                            </div>
                        </Col>
                        <div className="only-mobile" style={{ height: '1rem', width: '100%' }}></div>
                        <Col sm={12} lg={4} md={4} className="portalSegmentFeature">

                            <div className="portalSegmentItemImage">
                                <img src={SecureImage} alt="" />
                            </div>
                            <div className="portalSegmentItemInfo">
                                <div className="portalSegmentItemTitle z2 pointer" onClick={() => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/advantages';
                                }}>Secure</div>
                                <div className="portalSegmentItemLi z3">- No rugs, LP automatically and permanently locked</div>
                                <div className="portalSegmentItemLi z3">- Funds kept in token smart contract</div>
                                <div className="portalSegmentItemLi z3">- Audited by top contract aduitor</div>
                            </div>
                        </Col>
                        <div className="only-mobile" style={{ height: '1rem', width: '100%' }}></div>
                        <Col sm={12} lg={4} md={4} className="portalSegmentFeature">
                            <div className="portalSegmentItemImage">
                                <img src={FunImage} alt="" />
                            </div>
                            <div className="portalSegmentItemInfo">
                                <div className="portalSegmentItemTitle z2 pointer" onClick={() => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/advantages';
                                }}>Fun</div>
                                <div className="portalSegmentItemLi z3"> - Easy to participate, just send ETH to token CA</div>
                                <div className="portalSegmentItemLi z3"> - Creator earns LP transaction fee revenue</div>
                                <div className="portalSegmentItemLi z3"> - Various memes themes creators</div>
                            </div>
                        </Col>
                    </div>
                </div>
                {/*  */}

                <div className="divide"></div>
                <div className="portalSegment only-pc">
                    <div className="portalSegmentTitle">Roadmap</div>
                    <div className="portalSegmentProjects portalSegmentRoadmap ">
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage">
                                <img src={RoadMapImage1} alt="" />
                            </div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>Rocket Protocol</div>
                                <div className="z4">August 2024</div>
                            </div>
                        </div>
                        {/*  */}
                        <img src={RightArrowIcon} alt="" className="roadmapArrow"></img>
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage"><img src={RoadMapImage2} alt="" /> </div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>EIP Proposal</div>
                                <div className="z4">Q3 2024</div>
                            </div>
                        </div>
                        {/*  */}
                        <img src={RightArrowIcon} alt="" className="roadmapArrow"></img>
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage"> <img src={RoadMapImage3} alt="" /></div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>Meme to Earn</div>
                                <div className="z4">Q3 2024</div>
                            </div>
                        </div>
                        {/*  */}
                        <img src={RightArrowIcon} alt="" className="roadmapArrow"></img>
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage"> <img src={RoadMapImage4} alt="" /></div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>Launchpad</div>
                                <div className="z4">Q3 2024</div>
                            </div>
                        </div>
                        {/*  */}
                        <img src={RightArrowIcon} alt="" className="roadmapArrow"></img>
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage"> <img src={RoadMapImage5} alt="" /></div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>Launchpool</div>
                                <div className="z4">Q3 2024</div>
                            </div>
                        </div>
                        {/*  */}
                        <img src={RightArrowIcon} alt="" className="roadmapArrow"></img>
                        {/*  */}
                        <div className="portalRoadmapItem">
                            <div className="portalRoadmapItemImage"> <img src={RoadMapImage6} alt="" /></div>
                            <div className="portalRoadmapItemInfo">
                                <div className="z3" onClick={(e) => {
                                    document.location.href = 'https://rocketmeme.gitbook.io/en/roadmap';
                                }}>More</div>
                                <div className="z4">2025</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* timeline mobile */}
                <div className="portalSegment only-mobile">
                    <div className="portalSegmentTitle">Roadmap</div>
                    <div className="timeline">
                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>Rocket Protocol</div>
                                {/* <p>Augest 2024</p> */}
                                <span className="date">August 2024</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>EIP Proposal</div>
                                {/* <p>Description of event 2.</p> */}
                                <span className="date">Q3 2024</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>MEME to Earn</div>
                                {/* <p>Description of event 3.</p> */}
                                <span className="date">Q3, 2024</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>Launchpad</div>
                                {/* <p>Description of event 3.</p> */}
                                <span className="date">Q3, 2024</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>Launchpool</div>
                                {/* <p>Description of event 3.</p> */}
                                <span className="date">Q4, 2024</span>
                            </div>
                        </div>

                        <div className="timeline-item">
                            <div className="timeline-content">
                                <div>More</div>
                                {/* <p>Description of event 3.</p> */}
                                <span className="date">2025</span>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="portalSegment">
          <div className="portalSegmentTitle">$ROCKET Tokenomics</div>
          <div className="portalSegmentProjects z3">
            <Col sm={12} lg={6} md={6}>
            $ROCKET is the governance token of Rocket Protocol, used in scenarios such as community voting and launchpool. 
              <div className="portalSegmentItemInfo">
                <div style={{ height: '1rem' }}></div>
                <div className="portalSegmentItemLi"> - 25% FairMint</div>
                <div className="portalSegmentItemLi"> - 25% Added to LP</div>
                <div className="portalSegmentItemLi"> - 20% Meme to Earn</div>
                <div className="portalSegmentItemLi"> - 15% Market Capitalization Management Fund</div>
                <div className="portalSegmentItemLi"> - 10% Airdropped to coin creators</div>
                <div className="portalSegmentItemLi"> - 5% Airdropped to MEME coin holders</div>
              </div>
            </Col>
            <Col sm={12} lg={6} md={6}>
              <div className="only-mobile" style={{ height: '2rem' }}></div>
              <img src={PieChartImage} alt="" style={{
                width: '100%',
              }} />
            </Col>
          </div>
        </div> */}

                {/* <div className="divide"></div>
        <div className="portalSegment">
          <div className="portalSegmentTitle">Partners</div>
          <div className="protalSegmentPartners">
            <div className="portalSegmentPartner" onClick={() => {
              window.open('https://x.com/DinoBase');
            }}>
              <img src="https://pbs.twimg.com/profile_images/1771134544751583232/i1vmU9pH_400x400.jpg" alt="dinobase"></img>
              @DinoBase
            </div>
          </div>
        </div> */}
            </div>
            <Footer />
        </div>
    }
}

export default PortalPage;